<main wrapper>
  <app-joyfood-header (backClick)="onBackClick()" cdkFocusInitial>
    {{ goods?.goodsNm }}
  </app-joyfood-header>

  <div *ngIf="goods; else loading">
    <img *ngIf="goods.imgUrl" [src]="goods.imgUrl" class="goods-image" />

    <div class="product-explain-wrap" fxLayout fxLayoutAlign="center center">
      <span class="product-explain-text">
        {{ goods.goodsCn }}
      </span>
    </div>

    <div class="content-wrap" fxLayout="column" fxLayoutGap="16px">
      <ng-container *ngFor="let info of $any(goods).info">
        <ng-container *ngIf="!info.expandable">
          <mat-card>
            <mat-card-header>
              <mat-card-title>{{ info.title }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div [innerHTML]="info.content | safeHtml"></div>
            </mat-card-content>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="info.expandable">
          <mat-expansion-panel>
            <mat-expansion-panel-header
              collapsedHeight="20px"
              expandedHeight="20px"
            >
              <mat-panel-title>{{ info.title }}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div [innerHTML]="info.content | safeHtml"></div>
            </ng-template>
          </mat-expansion-panel>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isOrderable">
        <div class="price-wrap" *ngIf="goods.goodsAmt">
          <span class="product-amount-title">{{ 'price' | translate }}</span>

          <span class="product-price-text">{{
            goods.goodsAmt | currency
          }}</span>
        </div>

        <app-goods-opt-list
          [goodsOptList]="goods.goodsOptList"
          (formGroupInit)="onFormGroupInit($event)"
        ></app-goods-opt-list>
      </ng-container>
    </div>
    <div *ngIf="isOrderable" class="footer" fxLayout fxLayoutGap="6px">
      <button
        *ngIf="goods.attrListJson.includes('DELIVERY')"
        mat-flat-button
        round
        color="primary"
        class="add-cart-button"
        (click)="onCartClick('delivery')"
      >
        {{ 'deliveryBtn' | translate }}
      </button>
      <button
        *ngIf="goods.attrListJson.includes('TAKEOUT')"
        mat-flat-button
        round
        color="accent"
        class="add-cart-button"
        (click)="onCartClick('takeout')"
      >
        {{ 'takeoutBtn' | translate }}
      </button>
    </div>
  </div>
  <ng-template #loading>
    <app-loading></app-loading>
  </ng-template>
</main>
