<div
  fxLayout
  safe-bottom
  safe-side
  class="footer-wrapper"
  [style.height.px]="height"
>
  <div
    class="footer-menu"
    routerLink="/main/home"
    routerLinkActive="on"
    [replaceUrl]="true"
  >
    <div class="footer-menu-home"></div>
    <label>{{ 'home' | translate }} </label>
  </div>
  <div
    class="footer-menu"
    routerLink="/main/coupon"
    routerLinkActive="on"
    [replaceUrl]="true"
  >
    <div class="footer-menu-coupon"></div>
    <label>{{ 'coupon' | translate }}</label>
  </div>
  <div
    class="footer-menu"
    routerLink="/main/news"
    routerLinkActive="on"
    [replaceUrl]="true"
  >
    <div class="footer-menu-mail"></div>
    <label>{{ 'news' | translate }}</label>
  </div>
  <div
    class="footer-menu"
    routerLink="/main/history"
    routerLinkActive="on"
    [replaceUrl]="true"
  >
    <div class="footer-menu-history"></div>
    <label>{{ 'history' | translate }}</label>
  </div>
  <div
    class="footer-menu"
    routerLink="/main/my"
    routerLinkActive="on"
    [replaceUrl]="true"
  >
    <div class="footer-menu-my"></div>
    <label>My</label>
  </div>
</div>
<div class="footer-spacer" [style.padding-top.px]="height"></div>
