import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from './dialog/dialog.module';
import { JoyfoodFooterModule } from './joyfood-footer/joyfood-footer.module';
import { JoyfoodHeaderModule } from './joyfood-header/joyfood-header.module';
import { JoyfoodLayoutModule } from './joyfood-layout/joyfood-layout.module';
import { MoreButtonModule } from './more-button/more-button.module';

const modules = [
  CommonModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  MatIconModule,
  MatButtonModule,
  TranslateModule,
  DialogModule,
  JoyfoodLayoutModule,
  JoyfoodHeaderModule,
  JoyfoodFooterModule,
  MoreButtonModule,
];

/**
 * 공통 모듈
 */
@NgModule({
  declarations: [],
  imports: modules,
  exports: modules,
})
export class SharedModule {}
