<div class="dialog">
  <mat-icon mat-dialog-close class="close-button">close</mat-icon>

  <p class="address-title">{{ 'address' | translate }}</p>

  <h3 class="address">{{ recentAddress.address }}</h3>

  <p class="ask-delivery">{{ 'MSG.askDeliveryAddress' | translate }}</p>

  <div fxLayoutGap="16px">
    <button
      fxFlex="noshrink"
      mat-flat-button
      mat-dialog-close
      round
      color="accent"
      routerLink="/address"
    >
      {{ 'selectOtherAddress' | translate }}
    </button>

    <button
      fxFlex="noshrink"
      mat-flat-button
      mat-dialog-close
      round
      color="primary"
      routerLink="/delivery/goods"
    >
      {{ 'order' | translate }}
    </button>
  </div>
</div>
