import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { JoyfoodLayoutComponent } from './components/joyfood-layout/joyfood-layout.component';
import { RouteGuard } from './core/route.guard';

const routes: Routes = [
  {
    path: 'main',
    component: JoyfoodLayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./components/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'coupon',
        loadChildren: () =>
          import('./components/coupon/coupon.module').then(
            (m) => m.CouponModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'news',
        loadChildren: () =>
          import('./components/news/news.module').then((m) => m.NewsModule),
      },
      {
        path: 'history',
        loadChildren: () =>
          import('./components/history/history.module').then(
            (m) => m.HistoryModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'my',
        loadChildren: () =>
          import('./components/my/my.module').then((m) => m.MyModule),
      },
    ],
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./components/register/register.module').then(
        (m) => m.RegisterModule
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./components/profile/profile.module').then(
        (m) => m.ProfileModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'takeout/mrhst',
    loadChildren: () =>
      import('./components/mrhst/mrhst.module').then((m) => m.MrhstModule),
    data: { type: 'takeout' },
  },
  {
    path: 'mrhst',
    loadChildren: () =>
      import('./components/mrhst/mrhst.module').then((m) => m.MrhstModule),
  },
  {
    path: 'address',
    loadChildren: () =>
      import('./components/address/address.module').then(
        (m) => m.AddressModule
      ),
  },
  {
    path: 'takeout/goods',
    loadChildren: () =>
      import('./components/goods/goods.module').then((m) => m.GoodsModule),
    data: { type: 'takeout' },
    canActivate: [AuthGuard],
  },
  {
    path: 'delivery/goods',
    loadChildren: () =>
      import('./components/goods/goods.module').then((m) => m.GoodsModule),
    data: { type: 'delivery' },
    canActivate: [AuthGuard],
  },
  {
    path: 'info/goods',
    loadChildren: () =>
      import('./components/goods/goods.module').then((m) => m.GoodsModule),
    data: { type: 'info' },
  },
  {
    path: 'notice',
    loadChildren: () =>
      import('./components/bbs-list/bbs-list.module').then(
        (m) => m.BbsListModule
      ),
    data: { bbsType: 'NTC' },
  },
  {
    path: 'event',
    loadChildren: () =>
      import('./components/bbs-list/bbs-list.module').then(
        (m) => m.BbsListModule
      ),
    data: { bbsType: 'EVNT' },
  },
  {
    path: 'point-hist',
    loadChildren: () =>
      import('./components/point-hist/point-hist.module').then(
        (m) => m.PointHistModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'grade',
    loadChildren: () =>
      import('./components/grade-guide/grade-guide.module').then(
        (m) => m.GradeGuideModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    canActivate: [AuthGuard],
    children: [],
  },
  { path: 'logout', canActivate: [RouteGuard], children: [] },
  { path: '**', redirectTo: 'main/home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
