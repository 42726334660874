import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  filter,
  interval,
  map,
  mergeMap,
  of,
  takeUntil,
  tap,
} from 'rxjs';
import { DialogService } from 'src/app/components/dialog/dialog.service';
import { Goods } from 'src/app/model/goods/goods';
import { GoodsGrp } from 'src/app/model/goods/goods-grp';
import { Mrhst } from 'src/app/model/mrhst/mrhst';
import { MrhstOrdr } from 'src/app/model/mrhst/mrhst-ordr';
import { PageRepositoryService } from 'src/app/repository/abstract-repository.service';
import { CommonService } from 'src/app/repository/common.service';
import { GoodsDeliveryService } from 'src/app/repository/goods/goods-delivery.service';
import { GoodsGrpService } from 'src/app/repository/goods/goods-grp.service';
import { GoodsMenuService } from 'src/app/repository/goods/goods-menu.service';
import { GoodsTakeoutService } from 'src/app/repository/goods/goods-takeout.service';
import { MrhstService } from 'src/app/repository/mrhst/mrhst.service';
import { CartService } from 'src/app/services/cart.service';
import {
  AddressHist,
  HistStorageService,
  MrhstHist,
} from 'src/app/services/hist-storage.service';
import { environment } from 'src/environments/environment';
import { CartComponent } from '../cart/cart.component';
import { GoodsDetailComponent } from '../goods-detail/goods-detail.component';
import { GoodsInfoComponent } from '../goods-info/goods-info.component';

export const isBreaktime = (date: Date): boolean => {
  // TODO: cwi: 추후 서버에서 브레이크타임 관리할수 있도록 개편
  const now = dayjs(date);
  const currnetDay = now.day();
  const currentTime = Number(now.format('HHmm'));

  if (currnetDay !== 6 && currnetDay !== 0) {
    const breakStartTime = 1430;
    const breakEndTime = 1630;

    if (currentTime >= breakStartTime && currentTime < breakEndTime) {
      return true;
    }
  }
  return false;
};

export const isMrhstOpen = (date: Date, mrhstOrdr: MrhstOrdr): boolean => {
  const now = dayjs(date);
  const currnetDay = now.day();
  const currentTime = Number(now.format('HHmm'));

  if (isBreaktime(date)) {
    return false;
  }

  // 0(일요일) -> 7(일요일)
  const openTmString: string = mrhstOrdr[`openTm${currnetDay || 7}`];
  const openTmSplit = openTmString.split(':');
  const openTmValue = Number(`${openTmSplit[0]}${openTmSplit[1]}`);

  if (currentTime < openTmValue) {
    return false;
  }

  // 0(일요일) -> 7(일요일)
  const closeTmString: string = mrhstOrdr[`closeTm${currnetDay || 7}`];
  const closeTmSplit = closeTmString.split(':');
  const closeTmValue = Number(`${closeTmSplit[0]}${closeTmSplit[1]}`);

  if (currentTime >= closeTmValue) {
    return false;
  }

  return true;
};

export const isDelivDisabled = (mrhstOrdr: MrhstOrdr): boolean => {
  const now = dayjs();
  const fromSplit = mrhstOrdr.delivDisableTmFrom?.split(':');
  const toSplit = mrhstOrdr.delivDisableTmTo?.split(':');
  const start = +`${fromSplit[0]}${fromSplit[1]}`;
  const end = +`${toSplit[0]}${toSplit[1]}`;
  const nowTm = +now.format('HHmm');

  if (start <= nowTm && nowTm <= end) {
    return true;
  }

  return false;
};

/**
 * 주문 가능한 최대 거리 (m)
 */
export const ORDR_DISTANCE_LIMIT = 1000 * 1000;

/**
 * 배달 가능한 최대 거리 (m)
 */
export const DELIV_DISTANCE_LIMIT = 3.5 * 1000;

/**
 * 테스트용 배달 가능한 최대 거리 (m)
 */
export const DELIV_DISTANCE_LIMIT_FOR_TEST = 2000 * 1000;

const HEADER_HEIGHT = 56;

const TAB_MINI_HEIGHT = 32;

const TAB_HEIGHT = 48;

/**
 * 헤더 + 그룹 + 패딩
 */
const MARGIN_TOP = HEADER_HEIGHT + TAB_HEIGHT + 16;

@Component({
  selector: 'app-goods',
  templateUrl: './goods.component.html',
  styleUrls: ['./goods.component.scss'],
})
export class GoodsComponent implements OnInit, AfterViewInit, OnDestroy {
  goodsGrpList$: Observable<GoodsGrp[]>;

  goodsList$: Observable<Goods[]>;

  isLoading$: Observable<boolean>;

  goodsMap: Map<number, Goods[]>;

  page: any;

  type: 'delivery' | 'takeout' | 'info';

  filter: 'DELIVERY' | 'TAKEOUT' = 'TAKEOUT';

  mrhstCheck$ = new Subject();

  mrhst: Mrhst;

  goodsGrpIdList: number[];

  goodsGrpScrollHeightList: number[];

  selectedGoodsGrpId = 1;

  scrollEventListener: (event: Event) => any;

  constructor(
    public cartService: CartService,
    private activatedRoute: ActivatedRoute,
    private histStorageService: HistStorageService,
    private dialogService: DialogService,
    private goodsGrpService: GoodsGrpService,
    private goodsTakeoutService: GoodsTakeoutService,
    private goodsDeliveryService: GoodsDeliveryService,
    private goodsMenuService: GoodsMenuService,
    private mrhstService: MrhstService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.type = this.activatedRoute.snapshot.data.type;

    if (this.type === 'info') {
      this.initGoodsGrp();
      this.initGoods();
      this.filter = this.activatedRoute.snapshot.queryParams.type;
    } else if (this.type === 'takeout') {
      this.findTakeoutMrhst();
    } else if (this.type === 'delivery') {
      this.findDeliveryMrhst();
    }

    this.initMrhst();
  }

  ngAfterViewInit(): void {
    const until$ = new Subject();

    interval(100)
      .pipe(
        takeUntil(until$),
        filter(
          () => !!this.goodsMap && !!Array.from(this.goodsMap.keys())?.length
        ),
        tap(() => this.setScrollEventListener()),
        filter(() => !!this.goodsGrpScrollHeightList?.length),
        tap(() => {
          until$.next(true);
          until$.complete();
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scrollEventListener);
  }

  onGoodsClick(item: Goods): void {
    const config = {
      data: { id: item.id, type: this.type },
      panelClass: 'fullscreen-modal',
      hasBackdrop: false,
    };

    if (this.type === 'info') {
      this.dialogService.matDialog.open(GoodsInfoComponent, config);
    } else {
      this.dialogService.matDialog.open(GoodsDetailComponent, config);
    }
  }

  onCartClick(): void {
    this.dialogService.matDialog.open(CartComponent, {
      panelClass: 'fullscreen-modal',
      hasBackdrop: false,
      data: { mrhst: this.mrhst, type: this.type },
    });
  }

  /**
   * 가격 표시
   * 기본 가격 설정되있으면 기본 가격, 필수 옵션이 있다면 필수 옵션 목록을 표시
   */
  getGoodsAmt(goods: any): number {
    if (!goods) return null;
    if (goods.goodsAmt) return goods.goodsAmt;
    if (goods.goodsOptList?.length) {
      const requiredList = goods.goodsOptList.filter(
        (value) => value.requiredFl
      );
      if (requiredList.length) {
        const [required] = requiredList;
        const [optMaster] = required.goodsOptMasterList.map(
          (opt) => opt.goodsOptAmt
        );
        return optMaster;
      }
    }
    return null;
  }

  isNew(dttm: string): boolean {
    const date = new Date(dttm);
    return Date.now() < date.setDate(date.getDate() + 3);
  }

  getGoodsList(id: number): Goods[] {
    let list = this.goodsMap?.get(id) ?? [];
    if (this.type === 'info') {
      list = list.filter((goods) => goods.attrListJson.includes(this.filter));
    }
    return list;
  }

  onGoodsGrpClick(id: number): void {
    this.selectedGoodsGrpId = id;

    this.changeTab();
    this.changeScroll();
  }

  onTakeoutClick(): void {
    this.filter = 'TAKEOUT';
  }

  onDeliveryClick(): void {
    this.filter = 'DELIVERY';
  }

  private initMrhst(): void {
    this.mrhstCheck$
      .pipe(
        /* mergeMap(() => {
          return this.commonService.getServerTime();
        }), */
        mergeMap((/* serverTime: Date */) => {
          // TODO: cwi: 추후 서버에서 브레이크타임 관리할수 있도록 개편
          /* if (
            this.type !== 'takeout' &&
            !isMrhstOpen(serverTime, this.mrhst.mrhstOrdr)
          ) {
            // 평일 브레이크타임
            return this.dialogService.alert('MSG.operBreaktime');
          }
          if (
            this.type === 'delivery' &&
            isDelivDisabled(this.mrhst.mrhstOrdr)
          ) {
            // 배달 불가 시간
            return this.dialogService.alert(
              this.mrhst.mrhstOrdr.delivDisableCn
            );
          } */

          this.initGoodsGrp();
          this.initGoods();

          return EMPTY;
        }),
        mergeMap(() => {
          if (environment.name !== 'prod') {
            return of(null);
          }

          window.history.back();

          return EMPTY;
        }),
        tap(() => {
          this.initGoodsGrp();
          this.initGoods();
        })
      )
      .subscribe();
  }

  private initGoodsGrp(): void {
    this.goodsGrpList$ = this.goodsGrpService.list$.pipe(
      map(({ content }) => {
        this.selectedGoodsGrpId = content[0]?.id;
        this.goodsGrpIdList = content.map(({ id }) => id);

        return content;
      })
    );

    // this.goodsGrpService.getPage({ activeFl: true, sort: 'sort,asc' });
  }

  private initGoods(): void {
    let service: PageRepositoryService<Goods>;
    if (this.type === 'delivery') {
      service = this.goodsDeliveryService;
    } else if (this.type === 'takeout') {
      service = this.goodsTakeoutService;
    } else {
      service = this.goodsMenuService;
    }

    this.isLoading$ = service.isLoading$;
    this.goodsList$ = service.list$.pipe(
      map((page) => {
        return page.content;
      }),
      tap((goodsList) => {
        this.goodsMap = new Map();
        this.organizeGoods(goodsList);
      })
    );
  }

  private setScrollEventListener(): void {
    const goodsGrpNmHtmlList = document.getElementsByClassName('goods-grp-nm');

    if (!goodsGrpNmHtmlList?.length) {
      return;
    }

    this.goodsGrpScrollHeightList = Array.from(goodsGrpNmHtmlList).map(
      (element: HTMLElement) =>
        element.offsetTop -
        MARGIN_TOP -
        (this.type === 'info' ? TAB_MINI_HEIGHT : 0)
    );

    [this.selectedGoodsGrpId] = this.goodsGrpIdList;

    this.scrollEventListener = (event: Event) => {
      const { scrollTop } = (event.target as Document).scrollingElement;
      let indexToScroll: number;

      this.goodsGrpScrollHeightList.find((goodsGrpScrollHeight, index) => {
        if (this.goodsGrpScrollHeightList[index + 1] === undefined) {
          indexToScroll = this.goodsGrpScrollHeightList.length - 1;
          return true;
        }

        if (this.goodsGrpScrollHeightList[index + 1] > scrollTop) {
          indexToScroll = index;
          return true;
        }

        return false;
      });

      if (this.selectedGoodsGrpId !== this.goodsGrpIdList[indexToScroll]) {
        this.selectedGoodsGrpId = this.goodsGrpIdList[indexToScroll];

        this.changeTab();
      }
    };

    window.addEventListener('scroll', this.scrollEventListener);
  }

  /**
   * 테이크아웃시 선택한 매장이 주문 가능한지 확인
   */
  private findTakeoutMrhst(): void {
    const hist: MrhstHist = this.histStorageService.getHist('mrhst')[0];

    this.mrhstService
      .findItem(hist?.id || 1)
      .pipe(
        mergeMap((mrhst) => {
          this.mrhst = mrhst;
          this.mrhstCheck$.next(null);
          return EMPTY;
        }),
        catchError((e) => {
          return this.dialogService.getNoAvailMrhstError('tkout', e);
        })
      )
      .subscribe();
  }

  /**
   * 딜리버리시 주문 가능한 가장 가까운 매장 확인
   */
  private findDeliveryMrhst(): void {
    const hist: AddressHist = this.histStorageService.getHist('address')[0];
    const { lat, lng } = hist;

    this.mrhstService
      .findPage({
        /* openFl: true,
        ordrFl: true,
        delivFl: true, */
        lat,
        lng,
        distance:
          environment.name === 'prod'
            ? DELIV_DISTANCE_LIMIT
            : DELIV_DISTANCE_LIMIT_FOR_TEST,
        size: 1,
      })
      .pipe(
        map(({ content }) => content),
        mergeMap((mrhstList) => {
          if (mrhstList?.length < 1) {
            return this.dialogService.getNoAvailMrhstError('deliv');
          }

          [this.mrhst] = mrhstList;

          if (
            this.mrhst.distance > DELIV_DISTANCE_LIMIT &&
            environment.name !== 'prod'
          ) {
            this.mrhstCheck$.next(null);
            this.dialogService
              .alert(
                '주변에 배달 가능한 매장이 없습니다. 개발환경이므로 주문을 진행합니다.'
              )
              .subscribe();
            return EMPTY;
          }

          this.mrhstCheck$.next(null);
          return EMPTY;
        }),
        catchError((e) => {
          return this.dialogService.getNoAvailMrhstError('deliv', e);
        })
      )
      .subscribe();
  }

  private organizeGoods(goodsList: Goods[]): void {
    goodsList.forEach((goods) => {
      const { id } = goods.goodsGrp;
      if (this.goodsMap.has(id)) {
        this.goodsMap.get(id).push(goods);
      } else {
        this.goodsMap.set(id, [goods]);
      }
    });
  }

  private changeTab(): void {
    document
      .getElementById(`goods-grp-wrapper-${this.selectedGoodsGrpId}`)
      ?.scrollIntoView({ inline: 'start' });
  }

  private changeScroll(): void {
    const { offsetTop } = document.getElementById(
      `goods-grp-nm-${this.selectedGoodsGrpId}`
    );

    window.scrollTo({
      // 헤더 + 탭 + 패딩
      top:
        offsetTop - MARGIN_TOP - (this.type === 'info' ? TAB_MINI_HEIGHT : 0),
    });
  }
}
