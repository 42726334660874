import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { JoyfoodFooterModule } from '../joyfood-footer/joyfood-footer.module';
import { JoyfoodLayoutComponent } from './joyfood-layout.component';

@NgModule({
  declarations: [JoyfoodLayoutComponent],
  imports: [CommonModule, RouterModule, FlexLayoutModule, JoyfoodFooterModule],
  exports: [JoyfoodLayoutComponent],
})
export class JoyfoodLayoutModule {}
