import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, EMPTY, finalize, mergeMap, of, tap } from 'rxjs';
import { UserInfoCheckExistService } from '../../../repository/user/user-info-check-exist.service';
import { UserInfoService } from '../../../repository/user/user-info.service';
import { DialogService } from '../dialog.service';

export const matchValidator = (matchTo: string): ValidatorFn | null => {
  return (control: AbstractControl): ValidationErrors | null => {
    return control?.parent?.get(matchTo)?.value === control?.value
      ? null
      : { match: true };
  };
};

@Component({
  selector: 'app-reset-pw',
  templateUrl: './reset-pw.component.html',
  styleUrls: ['./reset-pw.component.scss'],
})
export class ResetPwComponent implements OnInit {
  resetPwForm: FormGroup = this.formBuilder.group({
    pw: [
      null,
      [Validators.required, Validators.minLength(8), Validators.maxLength(16)],
    ],
    pwMatch: [null, [Validators.required, matchValidator('pw')]],
  });

  isLoading = false;

  today = new Date();

  userId: number;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private userInfoService: UserInfoService,
    private userInfoCheckExistService: UserInfoCheckExistService,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<ResetPwComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: { receiver: string; certId: number }
  ) {}

  ngOnInit(): void {
    this.userInfoCheckExistService
      .checkExist({ userId: this.data.receiver })
      .pipe(
        mergeMap((res) => {
          if (!res?.userInfoIdList?.length) {
            return this.dialogService.alert('MSG.USER_NOT_FOUND').pipe(
              mergeMap(() => {
                this.dialogRef.close();
                return EMPTY;
              })
            );
          }
          return of(res);
        })
      )
      .subscribe(({ userInfoIdList }) => {
        [this.userId] = userInfoIdList;
      });
  }

  onRegisterClick(): void {
    this.resetPwForm.updateValueAndValidity();
    if (this.resetPwForm.invalid) {
      this.resetPwForm.markAllAsTouched();
      return;
    }

    const { pw } = this.resetPwForm.value;
    const userForm = {
      userPw: pw,
    };

    this.isLoading = true;

    this.userInfoService
      .updatePw(this.userId, userForm)
      .pipe(
        mergeMap(() => this.dialogService.alert('MSG.pwChanged')),
        tap(() => this.dialogRef.close()),
        catchError(() => {
          return this.dialogService.alert(
            this.translateService.instant('MSG.error')
          );
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe();
  }
}
