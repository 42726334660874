import { Component, OnInit } from '@angular/core';
import {
  HistStorageService,
  MrhstHist,
} from 'src/app/services/hist-storage.service';

@Component({
  selector: 'app-takeout',
  templateUrl: './takeout.component.html',
  styleUrls: ['./takeout.component.scss'],
})
export class TakeoutComponent implements OnInit {
  recentMrhst: MrhstHist;

  constructor(private histStorageService: HistStorageService) {}

  ngOnInit(): void {
    [this.recentMrhst] = this.histStorageService.getHist('mrhst');
  }
}
