import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { EmailComponent } from '../../cert/email/email.component';
import { DialogService } from '../dialog.service';
import { ResetPwComponent } from '../reset-pw/reset-pw.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('pwForm') pwForm: ElementRef;

  loginForm = this.formBuilder.group({
    id: [null, Validators.required],
    pw: [null, Validators.required],
    // shouldSaveId: [null],
  });

  showJoin = false;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    data: { showJoin: boolean },
    private router: Router,
    private matDialogRef: MatDialogRef<LoginComponent>,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private authService: AuthService
  ) {
    if (data) {
      this.showJoin = data.showJoin;
    }
  }

  ngOnInit(): void {}

  onIdKeyup(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.pwForm.nativeElement.focus();
    }
  }

  onPwKeyup(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.onLoginClick();
    }
  }

  onLoginClick(): void {
    if (this.loginForm.invalid) {
      this.dialogService.alert('MSG.checkLoginInfo').subscribe();
      return;
    }

    const { id, pw } = this.loginForm.value;

    this.authService
      .login(id, pw)
      .pipe(
        tap(() => {
          this.matDialogRef.close(true);
        }),
        catchError(() => {
          this.authService.clearAuth();
          return this.dialogService.alert('MSG.wrongLoginInfo');
        })
      )
      .subscribe();
  }

  onJoinClick(): void {
    this.router.navigateByUrl('/register/terms');
    this.matDialogRef.close(false);
  }

  onFindClick(): void {
    this.dialogService.matDialog.open(EmailComponent, {
      panelClass: 'fullscreen-modal',
      data: {
        redirectTo: ResetPwComponent,
        sendMailMsg: 'MSG.checkResetPwMailTimeLimit',
      },
    });
  }
}
