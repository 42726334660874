<div class="dialog">
  <mat-icon mat-dialog-close class="close-button">close</mat-icon>

  <p class="address-title">{{ 'mrhst' | translate }}</p>

  <h3 class="mrhst-nm">{{ recentMrhst.mrhstNm }}</h3>

  <p class="address">{{ recentMrhst.address }}</p>

  <p class="ask-delivery">{{ 'MSG.askTakeoutMrhst' | translate }}</p>

  <div fxLayoutGap="16px">
    <button
      fxFlex="noshrink"
      mat-flat-button
      mat-dialog-close
      round
      color="accent"
      routerLink="/takeout/mrhst"
    >
      {{ 'selectOtherMrhst' | translate }}
    </button>

    <button
      fxFlex="noshrink"
      mat-flat-button
      mat-dialog-close
      round
      color="primary"
      routerLink="/takeout/goods"
    >
      {{ 'order' | translate }}
    </button>
  </div>
</div>
