import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  diameter = 80;

  strokeWidth = 8;

  constructor() {}

  ngOnInit(): void {}
}
