import { Component, OnInit } from '@angular/core';
import {
  AddressHist,
  HistStorageService,
} from 'src/app/services/hist-storage.service';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss'],
})
export class DeliveryComponent implements OnInit {
  recentAddress: AddressHist;

  constructor(private histStorageService: HistStorageService) {}

  ngOnInit(): void {
    [this.recentAddress] = this.histStorageService.getHist('address');
  }
}
