<main wrapper>
  <div class="coupon-dialog">
    <app-joyfood-header (backClick)="onBackClick()">{{
      'coupon' | translate
    }}</app-joyfood-header>

    <app-coupon-wrapper [cpn]="cpn" [isDetail]="true">
      <div class="barcode-container" fxLayoutAlign="center center">
        <qrcode [qrdata]="cpn?.cpnNo" [width]="184" [margin]="0.5"></qrcode>
      </div>
    </app-coupon-wrapper>

    <section>
      <h4 class="caution-title">{{ 'caution' | translate }}</h4>
      <div separator></div>
      <p class="caution">
        {{ 'MSG.cpnCaution' | translate }}
      </p>
    </section>
  </div>
</main>
