import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Goods } from 'src/app/model/goods/goods';
import { ExtPageRepositoryService } from '../abstract-ext-repository.service';

@Injectable({
  providedIn: 'root',
})
export class GoodsDeliveryService extends ExtPageRepositoryService<Goods> {
  baseUri = `api/goods`;

  extFixedParams = {
    activeFl: true,
    attrList: 'DELIVERY',
    sort: ['sortNumber,asc', 'regDttm,desc'],
  };

  constructor(protected http: HttpClient) {
    super(http);
  }
}
