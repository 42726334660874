import { Injectable } from '@angular/core';
import { StorageService } from 'projects/pw-lib/src/public-api';

type HistType = 'address' | 'mrhst';

export interface AddressHist {
  address: string;
  lat: number;
  lng: number;
}

export interface MrhstHist {
  id: number;
  mrhstNm: string;
  address: string;
  lat: number;
  lng: number;
}

/**
 * 최대 배열 길이
 */
const HIST_LENGTH = 5;

/**
 * 이용했던 매장, 배송지 관리
 */
@Injectable({
  providedIn: 'root',
})
export class HistStorageService {
  constructor(private storageService: StorageService) {}

  /**
   * 이력 조회
   */
  getHist(type: HistType): any[] {
    return this.storageService.get(`${type}Hist`, []);
  }

  /**
   * 배열 첫번째 인덱스에 새 이력 추가
   */
  addHist(type: HistType, hist: any): void {
    const list = this.getHist(type);
    const index = this.findIndex(type, list, hist);

    if (index < 0) {
      list.unshift(hist);
      this.storageService.set(`${type}Hist`, list);
      return;
    }

    this.moveToFirst(type, list, hist, index);
  }

  /**
   * 이력 선택, 선택된 이력은 첫번째 인덱스로 이동
   */
  selectHist(type: HistType, index: number): void {
    if (index < 0) {
      return;
    }

    const list = this.getHist(type);
    const hist = list[index];
    this.moveToFirst(type, list, hist, index);
  }

  /**
   * 이력 삭제
   */
  deleteHist(type: HistType, index: number): void {
    const list = this.getHist(type);
    list.splice(index, 1);
    this.storageService.set(`${type}Hist`, list);
  }

  /**
   * hist 배열에서 원하는 값을 검색해 그 인덱스 반환, 없으면 -1 반환
   */
  private findIndex(type: HistType, list: any[], hist: any): number {
    return list.findIndex((value: any) => {
      if (type === 'address') {
        return value.address === hist.address;
      }

      return value.id === hist.id;
    });
  }

  /**
   * hist 배열 길이가 최대값을 넘지 않도록 자름
   */
  private cutHist(list: any[]): any[] {
    const { length } = list;

    if (length > HIST_LENGTH) {
      return list.splice(length - 1, length - HIST_LENGTH);
    }

    return [];
  }

  /**
   * 이력을 첫번째 인덱스로 이동
   */
  private moveToFirst(
    type: HistType,
    list: any[],
    hist: any,
    index: number
  ): void {
    if (index < 1) {
      return;
    }

    list.splice(index, 1);
    list.unshift(hist);
    this.cutHist(list);
    this.storageService.set(`${type}Hist`, list);
  }
}
