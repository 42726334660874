import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IGoodsOpt } from 'src/app/repository/goods-opt/goods-opt.model';

/**
 * 옵션 목록 컴포넌트
 */
@Component({
  selector: 'app-goods-opt-list',
  templateUrl: './goods-opt-list.component.html',
  styleUrls: ['./goods-opt-list.component.scss'],
})
export class GoodsOptListComponent implements OnInit, AfterViewInit {
  @Input() goodsOptList: IGoodsOpt[];

  /**
   * { 옵션id: 마스터id[] }
   * `formGroup` 입력하거나 `formGroupInit` 이벤트에서 출력되는 값 사용
   */
  @Input() formGroup = new FormGroup({});

  @Output() formGroupInit: EventEmitter<FormGroup> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.formGroupInit.emit(this.formGroup);
  }

  onFormControlInit(formControl: FormControl, id: number): void {
    this.formGroup.addControl(`${id}`, formControl);
  }
}
