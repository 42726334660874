import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { TranslateService } from '@ngx-translate/core';
import { IGoodsOpt } from 'src/app/repository/goods-opt/goods-opt.model';

/**
 * 옵션 제목, 제약 설명, 옵션 마스터 목록 컴포넌트
 */
@Component({
  selector: 'app-goods-opt',
  templateUrl: './goods-opt.component.html',
  styleUrls: ['./goods-opt.component.scss'],
})
export class GoodsOptComponent implements OnInit {
  @Input() goodsOpt: IGoodsOpt;

  /**
   * radio, checkbox 모두 배열 형태 값
   */
  @Input() formControl = new FormControl();

  @Output() formControlInit: EventEmitter<FormControl> = new EventEmitter();

  type: 'radio' | 'checkbox';

  /**
   * 옵션 제약 설명
   */
  desc: string;

  /**
   * 체크박스 최대 선택 개수
   */
  maxCnt: number;

  /**
   * 체크박스 최대 개수 도달 여부
   */
  isMax = false;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.initFormControl();
    this.setDesc();
    this.formControlInit.emit(this.formControl);
  }

  onRadioChange(event: MatRadioChange): void {
    // 1개짜리 배열
    this.formControl.setValue([event.value]);
  }

  onCheckboxChange(event: MatCheckboxChange, id: number): void {
    const valueSet = new Set(this.formControl.value);

    if (event.checked) {
      valueSet.add(id);
    } else {
      valueSet.delete(id);
    }

    // id 배열
    const valueList = Array.from(valueSet);
    this.formControl.setValue(valueList);

    this.isMax = this.maxCnt <= valueList.length;
  }

  isCheckboxDisabled(id: number): boolean {
    // 최대 개수 도달했을때 체크된 상태가 아니라면 disabled
    return (
      this.isMax && (this.formControl.value as Array<number>).indexOf(id) === -1
    );
  }

  private initFormControl(): void {
    const { minCnt, maxCnt } = this.goodsOpt;

    if (minCnt > maxCnt) {
      // maxCnt가 0이면 최대 제한 없음
      if (maxCnt !== 0) {
        // 오류
        this.type = null;
        return;
      }
    }

    if (minCnt === maxCnt && minCnt === 1) {
      this.type = 'radio';
      this.formControl.setValue([this.goodsOpt.goodsOptMasterList[0].id]);
      this.formControl.updateValueAndValidity();
      return;
    }

    this.type = 'checkbox';

    if (minCnt > 0) {
      this.formControl.addValidators(Validators.required);
      this.formControl.addValidators(Validators.minLength(minCnt));
    }

    if (maxCnt > 0) {
      this.maxCnt = maxCnt;
      this.formControl.addValidators(Validators.maxLength(maxCnt));
    }

    this.formControl.setValue([]);
    this.formControl.updateValueAndValidity();
  }

  private setDesc(): void {
    const { minCnt, maxCnt } = this.goodsOpt;

    if (minCnt === maxCnt && minCnt === 1) {
      return;
    }

    if (minCnt === maxCnt && minCnt > 1) {
      this.desc = `${minCnt}${this.translateService.instant(
        'unitCnt'
      )} ${this.translateService.instant('required')}`;

      return;
    }

    const list = [];

    if (minCnt > 0) {
      list.push(
        `${this.translateService.instant(
          'min'
        )} ${minCnt}${this.translateService.instant('unitCnt')}`
      );
    }

    if (maxCnt > 0) {
      list.push(
        `${this.translateService.instant(
          'max'
        )} ${maxCnt}${this.translateService.instant('unitCnt')}`
      );
    }

    this.desc = list.join(', ');
  }
}
