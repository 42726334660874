<main wrapper>
  <app-joyfood-header (backClick)="onBackClick()">
    {{ goods?.goodsNm }}
  </app-joyfood-header>

  <div *ngIf="goods; else loading">
    <img *ngIf="goods.imgUrl" [src]="goods.imgUrl" class="goods-image" />

    <div class="product-explain-wrap" fxLayout fxLayoutAlign="center center">
      <span class="product-explain-text">
        {{ goods.goodsCn }}
      </span>
    </div>

    <div class="content-wrap">
      <div class="amount-wrap">
        <span class="product-amount-title">{{ 'cnt' | translate }}</span>

        <div class="product-amount-wrap">
          <mat-icon
            class="amount-icon"
            [ngClass]="{ disable: goodsCnt === 1 }"
            (click)="onCntChange(-1)"
            >remove</mat-icon
          >

          <span class="amount-text">{{ goodsCnt }}</span>

          <mat-icon class="amount-icon" (click)="onCntChange(1)">add</mat-icon>
        </div>
      </div>

      <div class="price-wrap" *ngIf="goods.goodsAmt">
        <span class="product-amount-title">{{ 'price' | translate }}</span>

        <span class="product-price-text">{{
          goods.goodsAmt * goodsCnt | currency
        }}</span>
      </div>

      <app-goods-opt-list
        [goodsOptList]="goods.goodsOptList"
        (formGroupInit)="onFormGroupInit($event)"
      ></app-goods-opt-list>
    </div>

    <div class="footer" fxLayout fxLayoutGap="6px">
      <button
        mat-flat-button
        round
        color="primary"
        class="add-cart-button"
        (click)="onCartClick()"
      >
        {{ 'cart' | translate }}
        {{ (this.goods.goodsAmt + goodsOptAmt) * this.goodsCnt | currency }}
      </button>

      <ng-template #readyMrhst>
        <div class="footer-container-right-ready">
          {{ 'preparingStore' | translate }}
        </div>
      </ng-template>
    </div>
  </div>
  <ng-template #loading>
    <app-loading></app-loading>
  </ng-template>
</main>
