import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cpn } from 'src/app/model/cpn/cpn';
import { environment } from 'src/environments/environment';
import { PageRepositoryService } from '../abstract-repository.service';

@Injectable({
  providedIn: 'root',
})
export class CpnService extends PageRepositoryService<Cpn> {
  protected baseUri = 'api/cpn';

  serverUrl = environment.apiServerUrl;

  constructor(protected http: HttpClient) {
    super(http);
  }
}
