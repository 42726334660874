import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserApp } from '../../model/user/user-app';
import { PageRepositoryService } from '../abstract-repository.service';

@Injectable({
  providedIn: 'root',
})
export class UserAppService extends PageRepositoryService<UserApp> {
  protected baseUri = 'api/userApp';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
