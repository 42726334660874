import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-joyfood-footer',
  templateUrl: './joyfood-footer.component.html',
  styleUrls: ['./joyfood-footer.component.scss'],
})
export class JoyfoodFooterComponent implements OnInit {
  @Input() height = 56;

  constructor() {}

  ngOnInit(): void {}
}
