import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PageRepositoryService } from '../abstract-repository.service';

@Injectable({
  providedIn: 'root',
})
export class UserInfoCheckExistService extends PageRepositoryService<any> {
  protected baseUri = 'api/userInfo';

  constructor(protected http: HttpClient) {
    super(http);
  }

  checkExist(params: any): Observable<any> {
    return this.http.get(
      `${environment.apiServerUrl}/${this.baseUri}/checkExist`,
      { params }
    );
  }
}
