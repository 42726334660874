<main wrapper>
  <app-joyfood-header
    ><ng-container *ngIf="type !== 'info'">{{ type | translate }}</ng-container>
    {{ 'menu' | translate }}</app-joyfood-header
  >
  <ng-container *ngIf="goodsGrpList$ | async as goodsGrpList">
    <app-loading *ngIf="isLoading$ | async; else loaded"></app-loading>
    <ng-template #loaded>
      <!-- <ng-container *ngIf="type === 'info'">
        <div class="ordr-type-spacer"></div>
        <div class="ordr-type-list-wrapper">
          <div class="goods-grp-list">
            <div class="ordr-type-wrapper">
              <button
                class="goods-grp"
                [class.selected]="filter === 'TAKEOUT'"
                (click)="onTakeoutClick()"
              >
                {{ 'takeout' | translate }}
              </button>
            </div>
            <div class="ordr-type-wrapper">
              <button
                class="goods-grp"
                [class.selected]="filter === 'DELIVERY'"
                (click)="onDeliveryClick()"
              >
                {{ 'delivery' | translate }}
              </button>
            </div>
          </div>
        </div>
      </ng-container> -->
      <div class="goods-grp-spacer"></div>
      <div class="goods-grp-list-wrapper" [ngClass]="type">
        <div class="goods-grp-list">
          <div
            class="goods-grp-wrapper"
            [id]="'goods-grp-wrapper-' + goodsGrp.id"
            *ngFor="let goodsGrp of goodsGrpList"
          >
            <button
              class="goods-grp"
              [class.selected]="goodsGrp.id === selectedGoodsGrpId"
              (click)="onGoodsGrpClick(goodsGrp.id)"
            >
              {{ goodsGrp.goodsGrpNm }}
            </button>
          </div>
        </div>
      </div>
      <div class="goods-list" *ngIf="goodsList$ | async">
        <ng-container *ngFor="let goodsGrp of goodsGrpList">
          <h2 class="goods-grp-nm" [id]="'goods-grp-nm-' + goodsGrp.id">
            {{ goodsGrp.goodsGrpNm }}
          </h2>
          <mat-card
            class="goods-wrapper mat-elevation-z3"
            (click)="onGoodsClick(goods)"
            *ngFor="let goods of getGoodsList(goodsGrp.id)"
          >
            <mat-card-content>
              <div
                class="content-menu-wrap"
                fxLayout
                fxLayoutAlign="space-between center"
              >
                <div class="menu-info-wrap" fxFlex>
                  <div
                    class="menu-title-wrap"
                    fxLayout="column"
                    fxLayoutAlign="start"
                  >
                    <div class="menu-discount-wrap">
                      <div
                        class="new-badge"
                        position="unset new"
                        *ngIf="isNew(goods?.regDttm) && type !== 'info'"
                      >
                        NEW
                      </div>
                    </div>
                    <span class="menu-title">{{ goods?.goodsNm }}</span>
                  </div>

                  <div class="menu-explain-text">
                    {{ goods?.goodsCn }}
                  </div>

                  <div class="menu-price-text" *ngIf="type !== 'info'">
                    {{ getGoodsAmt(goods) | currency }}
                  </div>
                </div>

                <img
                  onerror="this.src = '/assets/img/goods-no-image.png'"
                  [src]="goods?.imgUrl"
                />
              </div>
            </mat-card-content>
          </mat-card>
          <div spacer></div>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>
  <!-- <app-joyfood-tabs color="accent" [stretchTabs]="false">
    <mat-tab
      [label]="goodsGrp.goodsGrpNm"
      *ngFor="let goodsGrp of goodsGrpList$ | async"
    >
      <div
        class="goods-list"
        fxLayout="column"
        fxLayoutGap="16px"
        *ngIf="goodsList$ | async"
      >
        <div loading-wrapper *ngIf="isLoading$ | async; else loaded">
          <mat-spinner></mat-spinner>
        </div>
        <ng-template #loaded>
          <mat-card
            (click)="onGoodsClick(goods)"
            *ngFor="let goods of goodsMap?.get(goodsGrp.id)"
          >
            <mat-card-content>
              <div
                class="content-menu-wrap"
                fxLayout
                fxLayoutAlign="space-between center"
              >
                <div class="menu-info-wrap" fxFlex>
                  <div
                    class="menu-title-wrap"
                    fxLayout="column"
                    fxLayoutAlign="start"
                  >
                    <div class="menu-discount-wrap">
                      <div
                        class="new-badge"
                        position="unset new"
                        *ngIf="isNew(goods?.regDttm) && type !== 'info'"
                      >
                        NEW
                      </div>
                    </div>
                    <span class="menu-title">{{ goods?.goodsNm }}</span>
                  </div>

                  <div class="menu-explain-text">
                    {{ goods?.goodsCn }}
                  </div>

                  <div class="menu-price-text" *ngIf="type !== 'info'">
                    {{ getGoodsAmt(goods) | currency }}
                  </div>
                </div>

                <img
                  onerror="this.src = '/assets/img/goods-no-image.png'"
                  [src]="goods?.imgUrl"
                />
              </div>
            </mat-card-content>
          </mat-card>
          <ng-container *ngIf="goodsMap.get(goodsGrp.id)?.length < 1">
            <div class="empty">
              <div class="alarm-text">{{ 'MSG.noMenu' | translate }}</div>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </mat-tab>
  </app-joyfood-tabs> -->
</main>

<button
  mat-fab
  type="button"
  color="primary"
  matBadgeColor="accent"
  class="cart-button"
  [matBadge]="cartService.getGoodsCnt(type)"
  (click)="onCartClick()"
  *ngIf="cartService.getGoodsCnt(type) && type !== 'info'"
>
  <mat-icon>shopping_cart</mat-icon>
</button>
