import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GoodsGrp } from 'src/app/model/goods/goods-grp';
import { PageRepositoryService } from '../abstract-repository.service';

@Injectable({
  providedIn: 'root',
})
export class GoodsGrpService extends PageRepositoryService<GoodsGrp> {
  protected baseUri = 'api/goodsGrp';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
