<main wrapper>
  <app-joyfood-header (backClick)="onBackClick()"
    >{{ 'email' | translate }} {{ 'cert' | translate }}</app-joyfood-header
  >
  <form class="email-form" [formGroup]="emailForm" (submit)="onSubmit($event)">
    <!-- <span class="title"
      ></span
    > -->
    <mat-form-field appearance="outline">
      <mat-label>{{ 'email' | translate }}</mat-label>
      <input
        formControlName="email"
        matInput
        type="email"
        (input)="onReset()"
      />
      <mat-error *ngIf="emailForm?.get('email')?.hasError('required')">
        {{ 'MSG.emailRequired' | translate }}
      </mat-error>
      <mat-error *ngIf="emailForm?.get('email')?.hasError('pattern')">
        {{ 'MSG.emailPattern' | translate }}
      </mat-error>
    </mat-form-field>
    <button
      round
      [disabled]="isRequestPending || isSent || remainTime > 0"
      mat-flat-button
      color="primary"
    >
      <div>
        <mat-spinner
          *ngIf="isRequestPending"
          [strokeWidth]="3"
          [diameter]="16"
        ></mat-spinner>
        <span>
          {{ 'send' | translate }}
        </span>
        <span class="remain-time" *ngIf="remainTime > 0">
          {{ remainTime | date: 'm:ss' }}
        </span>
      </div>
    </button>
  </form>
  <form
    class="email-form"
    [formGroup]="certForm"
    (submit)="onCertSubmit($event)"
  >
    <mat-form-field appearance="outline">
      <mat-label>{{ 'certNumber' | translate }}</mat-label>
      <input
        formControlName="cert"
        matInput
        type="text"
        minlength="6"
        maxlength="6"
        inputmode="numeric"
      />
      <mat-error *ngIf="certForm?.get('cert')?.hasError('required')">
        {{ 'MSG.certRequired' | translate }}
      </mat-error>
      <mat-error *ngIf="certForm?.get('cert')?.hasError('pattern')">
        {{ 'MSG.certPattern' | translate }}
      </mat-error>
    </mat-form-field>
    <button
      round
      [disabled]="certForm.disabled"
      mat-flat-button
      color="primary"
    >
      {{ 'cert' | translate }}
    </button>
  </form>
</main>
