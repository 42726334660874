import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { catchError, fromEvent, of, tap } from 'rxjs';
import { PgPayResult } from 'src/app/model/pg-pay/pg-pay-result';
import { EpsilonPgService } from 'src/app/services/epsilon-pg.service';
import { LinePgService } from 'src/app/services/line-pg.service';
import { DialogService } from '../dialog/dialog.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, AfterViewInit {
  isLoading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) private matDialogData: any,
    private matDialogRef: MatDialogRef<PaymentComponent>,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private linePgService: LinePgService,
    private epsilonPgService: EpsilonPgService
  ) {}

  ngOnInit(): void {
    const { pgParams } = this.matDialogData;
    const pgWrapper = document.getElementById('pg-wrapper');
    const { payMethod } = this.matDialogData;

    if (payMethod === 'PG_PAY') {
      this.epsilonPgService
        .requestEpsilon(pgParams, pgWrapper)
        .pipe(
          tap((pgPayResult: PgPayResult) => {
            this.matDialogRef.close(pgPayResult);
          }),
          catchError((error) => {
            this.closeWithAlert(error);
            return of();
          })
        )
        .subscribe();
    } else if (payMethod === 'LINE') {
      this.linePgService
        .requestLine(pgParams, pgWrapper)
        .pipe(
          tap((pgPayResult: PgPayResult) => {
            this.matDialogRef.close(pgPayResult);
          }),
          catchError((error) => {
            this.closeWithAlert(error);
            return of();
          })
        )
        .subscribe();
    }
  }

  ngAfterViewInit(): void {
    const ifr = document.getElementById('ifr');
    fromEvent(ifr, 'load')
      .pipe(
        tap(() => {
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  onBackClick(): void {
    this.closeWithAlert();
  }

  private closeWithAlert(message = 'MSG.cancelPg'): void {
    this.dialogService
      .alert(this.translateService.instant(message))
      .pipe(
        tap(() => {
          this.matDialogRef.close();
        })
      )
      .subscribe();
  }
}
