import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, mergeMap } from 'rxjs';
import {
  HistStorageService,
  MrhstHist,
} from 'src/app/services/hist-storage.service';
import { CommonService } from '../../repository/common.service';
import { MrhstService } from '../../repository/mrhst/mrhst.service';
import { isDelivDisabled, isMrhstOpen } from '../goods/goods.component';
import { AlertComponent } from './alert/alert.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { MenuSelectComponent } from './menu-select/menu-select.component';

/**
 * 머터리얼 컴포넌트를 이용한 대화창 서비스
 * @see `DialogModule`
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    public matDialog: MatDialog,
    public matSnackBar: MatSnackBar,
    private router: Router,
    private histStorageService: HistStorageService,
    private mrhstService: MrhstService,
    private commonService: CommonService
  ) {}

  /**
   * 확인 창
   * @param body 본문 내용
   * @param header 상단 내용
   * @param ok 버튼 내용
   */
  alert(body: string, header = 'alert', ok = 'ok'): Observable<any> {
    const dialogRef = this.matDialog.open(AlertComponent, {
      data: {
        body,
        header,
        ok,
      },
    });
    return dialogRef.beforeClosed();
  }

  /**
   * 이지선다형 창
   * @param body 본문 내용
   * @param header 상단 내용
   * @param right 오른쪽 버튼 내용
   * @param left 왼쪽 버튼 내용
   */
  confirm(
    body: string,
    header?: string,
    right = 'ok',
    left = 'no'
  ): Observable<'right' | 'left'> {
    const dialogRef = this.matDialog.open(ConfirmComponent, {
      data: {
        body,
        header,
        right,
        left,
      },
    });
    return dialogRef //
      .beforeClosed();
  }

  /**
   * 오류 창
   * @param body 본문 내용
   */
  error(body = 'MSG.error'): Observable<any> {
    return this.alert(body, 'error', 'ok');
  }

  /**
   * Opens a snackbar with a message and an optional action.
   * @param message The message to show in the snackbar.
   * @param action The label for the snackbar action.
   * @param config Additional configuration options for the snackbar.
   */
  snackbar(
    message: string,
    action: string = null,
    config: MatSnackBarConfig = { duration: 2000 }
  ): void {
    this.matSnackBar.open(message, action, config);
  }

  menuSelect(): void {
    this.matDialog
      .open(MenuSelectComponent, { maxWidth: '100%' })
      .beforeClosed();
  }

  takeout(): void {
    if (this.histStorageService.getHist('mrhst').length < 1) {
      // 이력 없으면 바로 매장 선택으로 이동
      this.router.navigateByUrl(`/takeout/mrhst`);
      return;
    }

    /* return this.matDialog
          .open(TakeoutComponent, { maxWidth: '280px' })
          .beforeClosed(); */
    this.router.navigateByUrl('/takeout/goods');
  }

  delivery(): void {
    if (this.histStorageService.getHist('address').length < 1) {
      // 이력 없으면 바로 배송지 선택으로 이동
      this.router.navigateByUrl(`/address`);
      return;
    }

    this.matDialog
      .open(DeliveryComponent, { maxWidth: '280px' })
      .beforeClosed();
  }

  getNoAvailMrhstError(
    type: 'deliv' | 'tkout',
    error?: Error
  ): Observable<any> {
    const hist: MrhstHist = this.histStorageService.getHist('mrhst')[0];

    return this.commonService.getServerTime().pipe(
      mergeMap((serverTime) => {
        return this.mrhstService.findItem(hist?.id || 1).pipe(
          mergeMap((mrhst) => {
            let msg = 'MSG.operUnavailable';
            if (!mrhst.mrhstOrdr.ordrFl) {
              msg = 'MSG.operTempStop';
            } else if (!isMrhstOpen(serverTime, mrhst.mrhstOrdr)) {
              msg = 'MSG.operBreaktime';
            } else if (type === 'deliv') {
              if (!mrhst.mrhstOrdr.delivFl) {
                msg = 'MSG.delivTempStop';
              } else if (isDelivDisabled(mrhst.mrhstOrdr)) {
                msg = 'MSG.operBreaktime';
              } else if (!error) {
                // 오류 없으면 거리 제한으로 판단
                msg = 'MSG.delivFar';
              }
            }

            return this.alert(msg);
          })
        );
      })
    );
  }
}
