<div class="coupon-dialog">
  <app-joyfood-header [hasBack]="isFromCart" (backClick)="onBackClick()">
    {{ 'coupon' | translate }}
  </app-joyfood-header>
  <ng-container *ngIf="cpnList$ | async as cpnList">
    <app-coupon-wrapper
      [cpn]="cpn"
      [selected]="cpn?.id === selectedCpn?.id"
      [disabled]="!hasCpnGoodsInCart(cpn)"
      (click)="onCpnClick(cpn)"
      *ngFor="let cpn of cpnList"
    ></app-coupon-wrapper>

    <app-more-button [pageService]="cpnService"></app-more-button>

    <summary
      empty-list
      [style.height]="'calc(100vh - 112px)'"
      *ngIf="!cpnList?.length"
    >
      {{ 'MSG.noCpn' | translate }}
    </summary>
  </ng-container>
  <app-loading *ngIf="cpnService.isListLoading$ | async"></app-loading>
</div>
