<div class="goods-opt-continer">
  <ng-container *ngFor="let goodsOpt of goodsOptList">
    <div class="goods-opt-wrapper" [formGroup]="formGroup">
      <app-goods-opt
        [goodsOpt]="goodsOpt"
        (formControlInit)="onFormControlInit($event, goodsOpt.id)"
      ></app-goods-opt>
    </div>
  </ng-container>
</div>
