import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-joyfood-layout',
  templateUrl: './joyfood-layout.component.html',
  styleUrls: ['./joyfood-layout.component.scss'],
})
export class JoyfoodLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  onLineClick(): void {
    window.location.assign('https://lin.ee/tYgipqa');
  }

  onInstagramClick(): void {
    window.location.assign('https://www.instagram.com/joyfood2020');
  }
}
