import { Injectable } from '@angular/core';
import { StorageService } from 'pw-lib';
import { GoodsOpt } from 'src/app/model/goods/goods-opt';

export type OrdrType = 'delivery' | 'takeout' | 'info';

export interface CartGoods {
  goodsId: number;
  goodsCnt: number;
  goodsAmt: number;
  goodsNm: string;
  goodsOptList: GoodsOpt[];
  goodsAttrJson: string;
}

@Injectable({
  providedIn: 'root',
})
export class CartService {
  #cartData: CartGoods[] = this.cartData;

  #cartData2: CartGoods[] = this.cartData2;

  constructor(private storageService: StorageService) {}

  get cartData(): CartGoods[] {
    return this.storageService.get('cartData');
  }

  set cartData(data: CartGoods[]) {
    this.#cartData = data;
    this.storageService.set('cartData', this.#cartData);
  }

  get cartData2(): CartGoods[] {
    return this.storageService.get('cartData2');
  }

  set cartData2(data: CartGoods[]) {
    this.#cartData2 = data;
    this.storageService.set('cartData2', this.#cartData2);
  }

  /**
   * 상품 추가
   * @param data 매장, 상품 정보
   */
  addData(data: CartGoods[], ordrType: OrdrType): void {
    if (ordrType === 'takeout') {
      // 기존 정보 있을때
      if (this.#cartData) {
        data.forEach((goods) => {
          // 주문 타입이 다른 상품이 있으면 초기화
          if (this.hasDiffOrderType(goods, ordrType)) {
            this.clear(ordrType);
            this.#cartData = [];
            this.cartData = this.#cartData;
          }
          // 기존에 추가된 상품 아니라면
          if (!this.addSameGoods(goods, ordrType)) {
            // 상품 추가, 저장
            this.#cartData.push(goods);
            this.cartData = this.#cartData;
          }
        });
        return;
      }
      // 없거나 다른 매장일때 전체 저장
      this.#cartData = data;
      this.cartData = this.#cartData;
    }
    if (ordrType === 'delivery') {
      // 기존 정보 있을때
      if (this.#cartData2) {
        data.forEach((goods) => {
          // 주문 타입이 다른 상품이 있으면 초기화
          if (this.hasDiffOrderType(goods, ordrType)) {
            this.clear(ordrType);
            this.#cartData2 = [];
            this.cartData2 = this.#cartData2;
          }
          // 기존에 추가된 상품 아니라면
          if (!this.addSameGoods(goods, ordrType)) {
            // 상품 추가, 저장
            this.#cartData2.push(goods);
            this.cartData2 = this.#cartData2;
          }
        });
        return;
      }
      // 없거나 다른 매장일때 전체 저장
      this.#cartData2 = data;
      this.cartData2 = this.#cartData2;
    }
  }

  /**
   * 카트에 특정 주문 타입의 상품이 있는지 확인
   * @param orderType 주문 타입
   * @returns 특정 주문 타입에 대한 상품이 있는지 여부
   */
  hasOrderType(orderType: OrdrType): boolean {
    if (orderType === 'takeout') {
      return this.cartData.some((v) => {
        return v.goodsAttrJson?.includes(orderType.toUpperCase());
      });
    }
    if (orderType === 'delivery') {
      return this.cartData2.some((v) => {
        return v.goodsAttrJson?.includes(orderType.toUpperCase());
      });
    }
    return false;
  }

  private hasDiffOrderType(data: CartGoods, ordrType: OrdrType): boolean {
    // 동일한 속성 없으면 주문 타입 다른걸로 판단
    if (ordrType === 'takeout') {
      return this.cartData.some((v) => {
        return !JSON.parse(data.goodsAttrJson ?? '[]')?.some((attr) => {
          return (
            v.goodsAttrJson?.includes(attr) &&
            (attr === 'DELIVERY' || attr === 'TAKEOUT')
          );
        });
      });
    }
    if (ordrType === 'delivery') {
      return this.cartData2.some((v) => {
        return !JSON.parse(data.goodsAttrJson ?? '[]')?.some((attr) => {
          return (
            v.goodsAttrJson?.includes(attr) &&
            (attr === 'DELIVERY' || attr === 'TAKEOUT')
          );
        });
      });
    }
    return false;
  }

  /**
   * 동일 상품 추가
   *
   * 상품, 옵션, 옵션 마스터를 비교하여 같으면 기존 상품 갯수 +1
   *
   * @returns 동일 상품 하나라도 있으면 `true` 아니면 `false`
   */
  private addSameGoods(data: CartGoods, ordrType: OrdrType): boolean {
    if (ordrType === 'takeout') {
      return this.cartData.some((v, i) => {
        if (v.goodsId === data.goodsId) {
          // 상품 옵션 비교
          if (
            v.goodsOptList.length === data.goodsOptList.length &&
            v.goodsOptList.every((opt, i1) => {
              if (opt.id !== data.goodsOptList[i1].id) return false;
              // 상품 옵션 마스터 비교
              if (
                opt.goodsOptMasterList.length ===
                data.goodsOptList[i1].goodsOptMasterList.length
              )
                return opt.goodsOptMasterList.every((mst, i2) => {
                  return (
                    mst.id === data.goodsOptList[i1].goodsOptMasterList[i2].id
                  );
                });
              return false;
            })
          ) {
            this.#cartData[i].goodsCnt += data.goodsCnt;
            this.cartData = this.#cartData;
            return true;
          }
        }
        return false;
      });
    }
    if (ordrType === 'delivery') {
      return this.cartData2.some((v, i) => {
        if (v.goodsId === data.goodsId) {
          // 상품 옵션 비교
          if (
            v.goodsOptList.length === data.goodsOptList.length &&
            v.goodsOptList.every((opt, i1) => {
              if (opt.id !== data.goodsOptList[i1].id) return false;
              // 상품 옵션 마스터 비교
              if (
                opt.goodsOptMasterList.length ===
                data.goodsOptList[i1].goodsOptMasterList.length
              )
                return opt.goodsOptMasterList.every((mst, i2) => {
                  return (
                    mst.id === data.goodsOptList[i1].goodsOptMasterList[i2].id
                  );
                });
              return false;
            })
          ) {
            this.#cartData2[i].goodsCnt += data.goodsCnt;
            this.cartData2 = this.#cartData2;
            return true;
          }
        }
        return false;
      });
    }
    throw new Error(`알 수 없는 주문 타입: ${ordrType}`);
  }

  /**
   * 선택 상품 삭제
   * @param payListIndex 삭제할 상품의 index 들
   */
  delItem(payListIndex: number[], ordrType: OrdrType): void {
    if (ordrType === 'takeout') {
      this.#cartData = this.#cartData.filter(
        (v, i) => payListIndex.indexOf(i) === -1
      );
      if (!this.#cartData.length) this.#cartData = null;
      this.cartData = this.#cartData;
    }
    if (ordrType === 'delivery') {
      this.#cartData2 = this.#cartData2.filter(
        (v, i) => payListIndex.indexOf(i) === -1
      );
      if (!this.#cartData2.length) this.#cartData2 = null;
      this.cartData2 = this.#cartData2;
    }
  }

  /**
   * 카트에 추가된 상품의 전체 가격
   */
  getCartAmt(ordrType: OrdrType): number {
    let amt = 0;
    if (ordrType === 'takeout') {
      if (!this.cartData) return amt;
      this.cartData.forEach((data) => {
        // 상품 가격
        amt += data.goodsAmt * data.goodsCnt;
        // 옵션 있으면
        if (data.goodsOptList)
          data.goodsOptList.forEach((opt) => {
            // 선택된 마스터 가격 추가
            opt.goodsOptMasterList.forEach((mst) => {
              amt += mst.goodsOptAmt * data.goodsCnt;
            });
          });
      });
    }
    if (ordrType === 'delivery') {
      if (!this.cartData2) return amt;
      this.cartData2.forEach((data) => {
        // 상품 가격
        amt += data.goodsAmt * data.goodsCnt;
        // 옵션 있으면
        if (data.goodsOptList)
          data.goodsOptList.forEach((opt) => {
            // 선택된 마스터 가격 추가
            opt.goodsOptMasterList.forEach((mst) => {
              amt += mst.goodsOptAmt * data.goodsCnt;
            });
          });
      });
    }
    return amt;
  }

  /**
   * 초기화
   */
  clear(ordrType: OrdrType): void {
    if (ordrType === 'takeout') {
      this.#cartData = null;
      this.cartData = this.#cartData;
    } else if (ordrType === 'delivery') {
      this.#cartData2 = null;
      this.cartData2 = this.#cartData2;
    } else {
      this.#cartData = null;
      this.cartData = this.#cartData;
      this.#cartData2 = null;
      this.cartData2 = this.#cartData2;
    }
  }

  /**
   * 상품 갯수 조회
   */
  getGoodsCnt(ordrType: OrdrType): number {
    if (ordrType === 'takeout') {
      if (this.cartData) {
        let goodsCnt = 0;

        this.cartData.forEach((res) => {
          goodsCnt += res.goodsCnt;
        });
        return goodsCnt;
      }
    }
    if (ordrType === 'delivery') {
      if (this.cartData2) {
        let goodsCnt = 0;

        this.cartData2.forEach((res) => {
          goodsCnt += res.goodsCnt;
        });
        return goodsCnt;
      }
    }
    return 0;
  }
}
