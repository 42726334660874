<div class="dialog">
  <mat-icon mat-dialog-close class="close-button">close</mat-icon>

  <div fxLayoutGap="16px">
    <button
      fxFlex="noshrink"
      mat-flat-button
      mat-dialog-close
      routerLink="/info/goods"
      [queryParams]="{ type: 'TAKEOUT' }"
    >
      <img src="/assets/img/menu_btn_take.jpg" />
    </button>

    <button
      fxFlex="noshrink"
      mat-flat-button
      mat-dialog-close
      routerLink="/info/goods"
      [queryParams]="{ type: 'DELIVERY' }"
    >
      <img src="/assets/img/menu_btn_deliv.jpg" />
    </button>
  </div>
</div>
