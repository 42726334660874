<div class="cpn-disabled" *ngIf="disabled">
  {{ 'MSG.cpnDisabled' | translate }}
</div>

<mat-card class="mat-elevation-z3 cpn">
  <mat-icon class="cpn-selected-check" *ngIf="selected">check_circle</mat-icon>
  <mat-card-content>
    <div fxLayout>
      <div class="img-wrapper" fxFlex="90px" *ngIf="!isDetail">
        <img [src]="cpn.cpnMaster?.imgUrl" />
      </div>
      <div fxFlex fxLayout="column">
        <p>
          <b class="cpn-benefits">
            {{ cpn.cpnMaster?.cpnNm }}
          </b>
        </p>
        <!-- <h4 class="cpn-nm">{{ cpn.cpnMaster.cpnNm }}</h4> -->
        <b class="cpn-cn" *ngIf="cpn.cpnMaster?.cpnCn">
          {{ cpn.cpnMaster.cpnCn }}
        </b>
        <div fxFlex="grow"></div>
        <p class="cpn-valid">
          ~ {{ cpn.cpnIssu.validEndDttm | date: 'yyyy-MM-dd' }}
        </p>
      </div>
    </div>
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>

<!-- <mat-card class="mat-elevation-z3 cpn">
  <mat-card-content>
    <div fxLayout>
      <div class="img-wrapper" fxFlex="90px">
        <img [src]="cpn.cpnMaster.imgUrl" />
      </div>
      <div fxFlex fxLayout="column">
        <p>
          <b
            class="cpn-benefits"
            *ngIf="getCpnBenefits(cpn.cpnMaster) as beneifts"
          >
            {{ beneifts }}
          </b>
        </p>
        <b class="cpn-cn" *ngIf="cpn.cpnMaster.cpnCn">
          {{ cpn.cpnMaster.cpnCn }}
        </b>
        <div fxFlex="grow"></div>
        <p class="cpn-valid">
          ~ {{ cpn.cpnIssu.validEndDttm | date: 'yyyy-MM-dd' }}
        </p>
      </div>
    </div>
    <ng-content></ng-content>
  </mat-card-content>
</mat-card> -->
