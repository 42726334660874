import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapComponent } from './google-map.component';

@NgModule({
  declarations: [GoogleMapComponent],
  imports: [CommonModule, HttpClientJsonpModule, GoogleMapsModule],
  exports: [GoogleMapComponent],
})
export class GoogleMapModule {}
