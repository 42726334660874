import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  forwardRef,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { PluginContext, PluginInfo } from '@toast-ui/editor';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IEditorConfig } from '../../../components/pw-tui-editor/pw-tui-editor.config';
import { PW_SUPPORTED_LOCALES } from '../../pw-locale';

@Component({
  selector: 'pw-locale-tui-editor-cva',
  templateUrl: './pw-locale-tui-editor.component.html',
  styleUrls: ['./pw-locale-tui-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => PwLocaleTuiEditorComponent),
    },
  ],
})
export class PwLocaleTuiEditorComponent
  implements OnInit, ControlValueAccessor, AfterViewChecked, OnDestroy
{
  #currentLocale: { name: string; value: string };

  /** 현재 편집중인 다국어 */
  @Input() set currentLocale(v: { name: string; value: string }) {
    this.#currentLocale = v;
    // this.findCurrentLocaleString();
  }

  get currentLocale(): { name: string; value: string } {
    // 현재 선택한 언어가 없다면 첫번째 언어를 기본으로 설정
    if (!this.#currentLocale) {
      [this.#currentLocale] = this.supportedLocales;
    }

    return this.#currentLocale;
  }

  @Output() currentLocaleChange = new EventEmitter<{
    name: string;
    value: string;
  }>();

  /** 지원하는 다국어 목록. 메뉴 오픈 상태에서 표시 */
  @Input() supportedLocales: { name: string; value: string }[];

  @Input() required = false;

  @Input() disabled = false;

  /**
   * 버튼에 선택한 언어를 표시할 방식
   *
   * * **short**: 언어 코드 (KO, JA...)
   * * **full**: 언어 명 (한국어, 日本語...)
   * * **none**: 표시하지 않음
   *
   * @default 'full'
   */
  @Input() buttonLocaleName: 'short' | 'full' | 'none' = 'full';

  /** formControl 값 중 현애 선택한 다국어에 맞는 텍스트 */
  currentValue: any = {};

  #editorConfig: IEditorConfig = {
    plugins: [[this.localePlugin, this]],
  };

  @Input()
  set config(value: IEditorConfig) {
    value.plugins.push([this.localePlugin, this]);
    this.#editorConfig = value;
  }

  get config(): IEditorConfig {
    return this.#editorConfig;
  }

  @Input() uploadUrl: string;

  onChange: any;

  onTouched: any;

  #isChangeRequired = false;

  #localeIconRef: ComponentRef<MatIcon>;

  #destroy$ = new Subject();

  constructor(
    @Optional()
    @Inject(PW_SUPPORTED_LOCALES)
    private supportedLocaleInject: { name: string; value: string }[],
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (!this.supportedLocales) {
      this.supportedLocales = this.supportedLocaleInject;
    }
    this.#localeIconRef?.instance?.ngOnInit();
  }

  ngAfterViewChecked(): void {
    this.#localeIconRef?.instance?.ngAfterViewChecked();
  }

  ngOnDestroy(): void {
    this.#destroy$.next(null);
    this.#destroy$.complete();
    this.#localeIconRef?.instance?.ngOnDestroy();
  }

  /**
   * 입력란의 메뉴에서 변경할 다국어를 선택했을 때 동작.
   *
   * 현재 form 값 중 현재 선택한 다국어에 맞는 텍스트를 가져와서 입력란에 설정한다.
   *
   * 상위로 전파하여 다른 입력란의 다국어 설정도 변경해야 한다
   * */
  onChangeLocale(locale: { name: string; value: string }): void {
    this.currentLocale = locale;

    // 상위로 전파하여 다른 입력란의 다국어 설정도 변경해야 한다
    this.currentLocaleChange.emit(this.currentLocale);
  }

  /**
   * object 중 표시 언어에 맞는 값(object > ja 값 등) 가져오기
   */
  getLocaleModel(): string {
    const localeValue = this.currentLocale.value;
    if (!this.currentValue[localeValue]) {
      this.currentValue[localeValue] = '';
    }

    return this.currentValue[localeValue];
  }

  /**
   * 각 현지화 필드의 값이 변경되었을 때 동작. 다시 form value에 반영한다
   */
  onChangeCurrentValue(value: string): void {
    const localeValue = this.currentLocale.value;
    this.currentValue[localeValue] = value;

    this.onChange(JSON.stringify(this.currentValue));
  }

  writeValue(obj: string): void {
    try {
      this.currentValue = JSON.parse(obj);
      if (this.currentValue == null) {
        this.currentValue = {};
      }
    } catch (e) {
      this.currentValue = {};
      this.supportedLocales.forEach((locale) => {
        this.currentValue[locale.value] = '';
      });
      this.currentValue[this.currentLocale.value] = obj;
      if (this.onChange) {
        this.onChange(JSON.stringify(this.currentValue));
      } else {
        this.#isChangeRequired = true;
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    if (this.#isChangeRequired) {
      this.onChange(JSON.stringify(this.currentValue));
      this.#isChangeRequired = false;
    }
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * 언어 변경 아이콘
   */
  private getLocaleIconElement(): HTMLElement {
    const factory: ComponentFactory<MatIcon> =
      this.componentFactoryResolver.resolveComponentFactory(MatIcon);
    this.#localeIconRef = factory.create(this.injector);
    const icon = this.#localeIconRef.location.nativeElement;
    icon.innerText = 'language';
    icon.style.color = '#555';

    const button = document.createElement('button');
    button.className = 'toastui-editor-toolbar-icons';
    button.style.cssText = `
      background-image: none;
      margin: 0;`;
    button.appendChild(icon);

    if (this.buttonLocaleName !== 'none') {
      button.style.cssText += `
      width: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 4px;`;

      const span = document.createElement('span');
      span.innerText =
        this.buttonLocaleName === 'short'
          ? this.currentLocale.value.toUpperCase()
          : this.currentLocale.name;
      this.currentLocaleChange.pipe(takeUntil(this.#destroy$)).subscribe(() => {
        span.innerText =
          this.buttonLocaleName === 'short'
            ? this.currentLocale.value.toUpperCase()
            : this.currentLocale.name;
      });

      button.appendChild(span);
    }

    return button;
  }

  /**
   * 언어 선택 팝업
   */
  private createPopupBody(
    context: PluginContext,
    component: PwLocaleTuiEditorComponent
  ): HTMLElement {
    const body = document.createElement('ul');
    body.append(
      ...this.supportedLocales.map((locale) => {
        const item = document.createElement('li');
        item.innerText = locale.name;
        item.setAttribute('aria-role', 'menuitem');
        item.setAttribute('data-value', locale.value);
        if (locale.value === component.currentLocale.value) {
          item.classList.add('selected');
        }
        component.currentLocaleChange
          .pipe(takeUntil(component.#destroy$))
          .subscribe(() => {
            if (locale.value === component.currentLocale.value) {
              item.classList.add('selected');
            } else {
              item.classList.remove('selected');
            }
          });
        item.onclick = () => {
          component.onChangeLocale(locale);
          component.changeDetectorRef.detectChanges();
          context.eventEmitter.emit('closePopup');
        };
        return item;
      })
    );
    return body;
  }

  /**
   * 언어 선택 Editor 플러그인
   */
  private localePlugin(
    context: PluginContext,
    component: PwLocaleTuiEditorComponent
  ): PluginInfo {
    const el = component.getLocaleIconElement();
    component.#localeIconRef.instance.ngOnInit();
    return {
      toolbarItems: [
        {
          groupIndex: 5,
          itemIndex: 0,
          item: {
            name: 'locale',
            el,
            tooltip: context.i18n.get('Choose language'),
            popup: {
              className: 'toastui-editor-popup-select-locale',
              body: component.createPopupBody(context, component),
            },
          },
        },
      ],
    };
  }
}
