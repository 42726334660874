import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { filter, Observable, tap } from 'rxjs';
import { GoogleMapService } from 'src/app/services/google-map.service';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnInit {
  @ViewChild(GoogleMap) googleMap: GoogleMap;

  #center: { lat: number; lng: number };

  /** 편집(마커 이동) 불가능 여부 */
  @Input() disabled = false;

  /** 지도 가운데 기준이 되는 좌표 */
  @Input()
  set center(v: { lat: number; lng: number }) {
    this.#center = v;
    if (v) {
      if (this.markers.length === 0) {
        // this.markers.push(v);
      } else {
        const [marker] = this.markers;
        if (v.lat !== marker.lat || v.lng !== marker.lng) {
          this.markers[0] = { ...v };
        }
      }
    }
  }

  get center(): { lat: number; lng: number } {
    return this.#center;
  }

  #options: google.maps.MapOptions;

  @Input()
  set options(options: google.maps.MapOptions) {
    this.#options = {
      mapTypeControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      ...options,
    };
  }

  get options(): google.maps.MapOptions {
    return this.#options;
  }

  /** 마커 위치 변경 시 위도 경도값 이벤트 */
  @Output()
  latLng: EventEmitter<{ lat: number; lng: number }> = new EventEmitter();

  /** 마커 클릭 시 이벤트 발생 */
  @Output() markerClick: EventEmitter<number> = new EventEmitter();

  /** 지도 마커 위치 */
  @Input() markers: google.maps.LatLngLiteral[] = [];

  /** 마커 옵션. 기본 드래그 방지 */
  markerOptions: google.maps.MarkerOptions = { draggable: false };

  /** 확대 레벨 */
  #zoom = 12;

  @Input() set zoom(value: number) {
    this.#zoom = value;
  }

  /** 확대 레벨 */
  get zoom(): number {
    return this.#zoom;
  }

  /**
   * 드래그 종료시 현재 중심 좌표
   */
  @Output() mapDragend: EventEmitter<google.maps.LatLng> = new EventEmitter();

  /**
   * 드래그 종료시 현재 중심 좌표
   */
  @Output() mapLoad: EventEmitter<void> = new EventEmitter();

  /** google maps api 가 로드되었는지 상태 확인 */
  apiLoaded: Observable<boolean>;

  /**
   * map-circle 옵션
   */
  @Input() circleOptions: google.maps.CircleOptions;

  constructor(private googleMapService: GoogleMapService) {
    this.apiLoaded = this.googleMapService.apiLoaded.pipe(
      filter((load) => load),
      tap(() => this.mapLoad.emit())
    );
  }

  ngOnInit(): void {}

  onMapclick(id?: number): void {
    this.markerClick.emit(id);
  }

  onMapDragend(): void {
    this.mapDragend.emit(this.googleMap.getCenter());
  }
}
