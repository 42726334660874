import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs';
import { Goods } from 'src/app/model/goods/goods';
import { GoodsService } from 'src/app/repository/goods/goods.service';
import { CommonService } from '../../repository/common.service';
import { IGoodsOptMaster } from '../../repository/goods-opt-master/goods-opt-master.model';
import { IGoodsOpt } from '../../repository/goods-opt/goods-opt.model';
import { MrhstService } from '../../repository/mrhst/mrhst.service';
import { CartGoods, CartService } from '../../services/cart.service';
import { HistStorageService } from '../../services/hist-storage.service';
import { DialogService } from '../dialog/dialog.service';

@Component({
  selector: 'app-goods-info',
  templateUrl: './goods-info.component.html',
  styleUrls: ['./goods-info.component.scss'],
})
export class GoodsInfoComponent implements OnInit {
  /**
   * 상품 아이디
   */
  goodsId: any;

  goods: Goods;

  goodsOptAmt = 0;

  goodsOptFormGroup: FormGroup;

  goodsOptMap: Map<number, IGoodsOpt> = new Map();

  goodsOptMasterMap: Map<number, IGoodsOptMaster> = new Map();

  /**
   * 주문 가능한 상품 여부
   *
   * 배달, 방문 하나라도 있으면
   * 가격, 옵션 선택 및 하단 버튼 표시
   */
  get isOrderable(): boolean {
    return (
      this.goods?.attrListJson?.includes('DELIVERY') ||
      this.goods?.attrListJson?.includes('TAKEOUT')
    );
  }

  private isCartClicked = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { id: number },
    private goodsService: GoodsService,
    private dialogRef: MatDialogRef<GoodsInfoComponent>,
    private dialogService: DialogService,
    private cartService: CartService,
    private histStorageService: HistStorageService,
    private mrhstService: MrhstService,
    private commonService: CommonService,
    private router: Router
  ) {
    this.goodsId = this.data.id;
  }

  ngOnInit(): void {
    this.initGoods();
  }

  onBackClick(): void {
    this.dialogRef.close();
  }

  onFormGroupInit(formGroup: FormGroup): void {
    this.goodsOptFormGroup = formGroup;

    this.goodsOptFormGroup.valueChanges
      .pipe(
        map((changes: { [id: number]: number[] }) => {
          // 배열 평탄화
          return [].concat(...Object.values(changes));
        }),
        tap((goodsOptMasterIdList: number[]) => {
          let goodsOptAmt = 0;

          goodsOptMasterIdList.forEach((goodsOptMasterId) => {
            goodsOptAmt +=
              this.goodsOptMasterMap.get(goodsOptMasterId).goodsOptAmt;
          });

          this.goodsOptAmt = goodsOptAmt;
        })
      )
      .subscribe();
  }

  onCartClick(type: 'delivery' | 'takeout'): void {
    if (this.isCartClicked) {
      return;
    }

    this.router.navigate([type, 'goods']).then((res) => {
      if (res) {
        if (this.goodsOptFormGroup.invalid) {
          this.goodsOptFormGroup.markAllAsTouched();
          this.dialogService.alert('MSG.checkGoodsOpt', 'alert').subscribe();
          return;
        }

        const goodsOptList = [];

        Object.keys(this.goodsOptFormGroup.value).forEach(
          (goodsOptId: string) => {
            if (this.goodsOptFormGroup.value[goodsOptId].length < 1) {
              return;
            }

            const goodsOptMasterList = [];

            this.goodsOptFormGroup.value[goodsOptId].forEach(
              (goodsOptMasterId: number) => {
                goodsOptMasterList.push(
                  this.goodsOptMasterMap.get(goodsOptMasterId)
                );
              }
            );

            goodsOptList.push({
              ...this.goodsOptMap.get(Number(goodsOptId)),
              goodsOptMasterList,
            });
          }
        );

        const cartData: CartGoods = {
          goodsId: this.goods.id,
          goodsAmt: this.goods.goodsAmt,
          goodsCnt: 1,
          goodsNm: this.goods.goodsNm,
          goodsOptList,
          goodsAttrJson: this.goods.attrListJson,
        };

        this.isCartClicked = true;
        this.cartService.addData([cartData], type);
        this.dialogRef.close();
      }
    });
  }

  /**
   * 상품 정보
   */
  private initGoods(): void {
    this.goodsService
      .findItem(this.goodsId)
      .pipe(
        tap((goods: Goods) => {
          /* eslint-disable no-param-reassign */
          if (goods.goodsCnJson) {
            try {
              (goods as any).info = JSON.parse(goods.goodsCnJson);
            } catch {
              //
            }
          }
          // 장바구니에 추가하기 위한 필수 옵션만 선택하도록 필터
          goods.goodsOptList = goods.goodsOptList.filter(
            (opt) => opt.minCnt > 0
          );
          /* eslint-enable no-param-reassign */
          this.goods = goods;

          goods.goodsOptList.forEach((goodsOpt) => {
            const copied = { ...goodsOpt };
            delete copied.goodsOptMasterList;
            this.goodsOptMap.set(goodsOpt.id, copied);

            goodsOpt.goodsOptMasterList.forEach((goodsOptMaster) => {
              this.goodsOptMasterMap.set(goodsOptMaster.id, goodsOptMaster);
            });
          });
        })
      )
      .subscribe();
  }
}
