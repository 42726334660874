<main wrapper>
  <app-joyfood-header (backClick)="onBackClick()"
    >{{ 'setAddrByLocation' | translate }}
  </app-joyfood-header>

  <div class="google-map-wrapper">
    <app-google-map
      [center]="initCenter"
      [zoom]="17"
      [options]="options"
      (mapDragend)="onMapDragend($event)"
      (mapLoad)="onMapLoad()"
    ></app-google-map>
    <mat-icon class="center-marker">location_on</mat-icon>
  </div>

  <div class="content">
    <p class="address">{{ address }}</p>

    <div separator></div>

    <mat-form-field appearance="outline" class="detail-form">
      <input
        matInput
        autocomplete="off"
        [placeholder]="'MSG.inputDetailAddr' | translate"
        [formControl]="detail"
      />
    </mat-form-field>

    <div class="spacer"></div>

    <button
      mat-flat-button
      round
      color="primary"
      [disabled]="isGeocodeLoading"
      (click)="onOkClick()"
    >
      {{
        (isGeocodeLoading ? 'checkingLocation' : 'delivToThisLocation')
          | translate
      }}
    </button>
  </div>
</main>
