import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cpn } from 'src/app/model/cpn/cpn';

@Component({
  selector: 'app-coupon-detail',
  templateUrl: './coupon-detail.component.html',
  styleUrls: ['./coupon-detail.component.scss'],
})
export class CouponDetailComponent implements OnInit {
  cpn: Cpn;

  constructor(
    private matDialogRef: MatDialogRef<CouponDetailComponent>,
    @Inject(MAT_DIALOG_DATA) dialogData: any
  ) {
    this.cpn = dialogData.cpn;
  }

  ngOnInit(): void {}

  onBackClick(): void {
    this.matDialogRef.close();
  }
}
