<main wrapper>
  <app-joyfood-header (backClick)="onBackClick()"
    >{{ 'payment' | translate }}
  </app-joyfood-header>

  <div loading-wrapper *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>

  <div id="pg-wrapper"></div>
</main>
