import { formatNumber } from '@angular/common';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Cpn } from 'src/app/model/cpn/cpn';
import { CpnMaster } from 'src/app/model/cpn/cpn-master';

@Component({
  selector: 'app-coupon-wrapper',
  templateUrl: './coupon-wrapper.component.html',
  styleUrls: ['./coupon-wrapper.component.scss'],
})
export class CouponWrapperComponent implements OnInit {
  @Input() cpn: Cpn;

  @Input() isDetail = false;

  @HostBinding('class.selected')
  @Input()
  selected = false;

  @HostBinding('class.disabled')
  @Input()
  disabled = false;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}

  getCpnBenefits(cpnMaster: CpnMaster): string {
    if (!cpnMaster) {
      return null;
    }
    const { cpnMasterType, cpnRatio, cpnAmt } = cpnMaster;

    if (cpnMasterType === 'RATIO') {
      return this.translateService.instant('MSG.cpnRatio', { cpnRatio });
    }

    if (cpnMasterType === 'AMT') {
      return this.translateService.instant('MSG.cpnAmt', {
        cpnAmt: formatNumber(cpnAmt, 'ja'),
      });
    }

    return null;
  }
}
