import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PgPayResult } from '../model/pg-pay/pg-pay-result';

export interface EpsilonRequestDto {
  /**
   * 회원 username
   *
   * 반각 영숫자 .-+/@ 64byte 이하
   */
  userId: string;

  /**
   * 회원 성명
   *
   * 64byte 이하
   */
  userName: string;

  /**
   * 회원 이메일
   *
   * 반각 영숫자 .-_@128byte 이하
   */
  userMailAdd: string;

  /**
   * 상품 코드
   */
  itemCode: string;

  /**
   * 결제 이름
   *
   * 64byte 이하
   */
  itemName: string;

  /**
   * 결제 금액
   *
   * 9,999,999이하
   */
  itemPrice: number;

  /**
   * 주문번호 반각 영숫자 32byte
   */
  orderNumber: string;

  /**
   * 예비 1
   *
   * 128byte 이하
   */
  memo1?: string;

  /**
   * 예비 2
   *
   * 128byte 이하
   */
  memo2?: string;

  /**
   * 상품 종류. 실물 / 컨텐츠
   */
  goodsType?: 'REAL' | 'CONT';
}

@Injectable({
  providedIn: 'root',
})
export class EpsilonPgService {
  private requestUrl = `${environment.apiServerUrl}/pg/epsilon`;

  private iframeWrapperSelector = 'ifrWrapper';

  private iframeSelector = 'ifr';

  private formSelector = 'pgForm';

  private form: HTMLFormElement;

  private iframeWrapper: HTMLDivElement;

  constructor() {}

  /**
   * epsilon 결제 요청
   * @param params 결제 요청 파라미터
   * @param target 결제 창 생성할 타겟
   */
  requestEpsilon(
    params: EpsilonRequestDto,
    target: HTMLElement = document.body
  ): Observable<PgPayResult> {
    // 엘리먼트 삭제 후 재생성
    this.iframeWrapper?.remove();
    this.form?.remove();
    this.iframeWrapper = this.getIframeWrapper();
    this.form = this.getHtmlForm(params);

    // 이벤트 리스너 등록
    const subject = new Subject<PgPayResult>();
    const messageListener = this.getMessageListener(subject);
    window.addEventListener('message', messageListener);

    // 엘리먼트 삽입 후 폼 제출
    target.appendChild(this.iframeWrapper);
    target.appendChild(this.form);
    this.form.submit();

    // 서브젝트 반환
    return subject.asObservable();
  }

  /**
   * 거래번호 생성
   * @returns 24자리 숫자
   */
  makeOrderNumber(): string {
    const today = new Date();
    const year = `${today.getFullYear()}`.padStart(4, '0');
    const month = `${today.getMonth() + 1}`.padStart(2, '0');
    const date = `${today.getDate() + 1}`.padStart(2, '0');
    const hours = `${today.getHours()}`.padStart(2, '0');
    const minutes = `${today.getMinutes()}`.padStart(2, '0');
    const seconds = `${today.getSeconds()}`.padStart(2, '0');
    const milliseconds = `${today.getMilliseconds()}`.padStart(3, '0');
    const random = `${Math.round(Math.random() * 9999999)}`.padStart(7, '0');

    return `${year}${month}${date}${hours}${minutes}${seconds}${milliseconds}${random}`;
  }

  /**
   * iframeWrapper 생성
   */
  private getIframeWrapper(): HTMLDivElement {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('id', this.iframeSelector);
    iframe.setAttribute('name', this.iframeSelector);

    const wrapper = document.createElement('div');
    wrapper.setAttribute('id', this.iframeWrapperSelector);
    wrapper.setAttribute('name', this.iframeWrapperSelector);
    wrapper.appendChild(iframe);

    return wrapper;
  }

  /**
   * form 생성
   */
  private getHtmlForm(params: { [key: string]: any } = {}): HTMLFormElement {
    const form = document.createElement('form') as HTMLFormElement;
    form.method = 'post';
    form.action = this.requestUrl;
    form.target = this.iframeSelector;
    form.id = this.formSelector;
    form.name = this.formSelector;

    Object.keys(params).forEach((key) => {
      const input = this.getHtmlInput(key, params[key]);
      form.appendChild(input);
    });

    return form;
  }

  /**
   * input 생성
   */
  private getHtmlInput(key: string, value: any): HTMLInputElement {
    const input = document.createElement('input') as HTMLInputElement;
    input.type = 'hidden';
    input.name = key;
    input.value = value;

    return input;
  }

  private getMessageListener(
    subject: Subject<PgPayResult>
  ): (event: MessageEvent) => void {
    const messageListener = (event: any) => {
      if (
        !event?.data ||
        event.data === 'unchanged' ||
        typeof event.data !== 'string'
      ) {
        return;
      }

      let data: any;

      try {
        data = JSON.parse(event.data);

        if (data.fail) {
          subject.error(data.fail);
          return;
        }

        if (data.success) {
          subject.next(data.success);
          subject.complete();
        }
      } catch (error) {
        subject.error(error);
      }

      window.removeEventListener('message', messageListener);
      this.iframeWrapper?.remove();
      this.form?.remove();
    };

    return messageListener;
  }
}
