import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { JoyfoodFooterComponent } from './joyfood-footer.component';

@NgModule({
  declarations: [JoyfoodFooterComponent],
  imports: [CommonModule, RouterModule, FlexLayoutModule, TranslateModule],
  exports: [JoyfoodFooterComponent],
})
export class JoyfoodFooterModule {}
