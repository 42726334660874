<app-joyfood-header (backClick)="onBackClick()">
  {{ type | translate }} {{ 'cart' | translate }}
  <span class="reset" right (click)="onAllDeleteClick()">{{
    'removeAll' | translate
  }}</span>
</app-joyfood-header>

<ng-container *ngIf="cartData; else noCartData">
  <div class="container" [formGroup]="form">
    <div class="content-wrap">
      <div
        class="content-product-wrap"
        [ngStyle]="{ 'margin-bottom.px': last ? 0 : 16 }"
        *ngFor="
          let item of cartData;
          index as i;
          last as last;
          trackBy: trackBy
        "
      >
        <div class="product-title-wrap">
          <span class="product-title">{{ item.goodsNm }}</span>
          <mat-icon class="close-icon" (click)="onDeleteBtnClick(i)"
            >close</mat-icon
          >
        </div>

        <span class="product-sub-text goods-opt" *ngIf="item.goodsAmt"
          >{{ 'default' | translate }} ({{ item.goodsAmt | currency }})</span
        >
        <span
          class="product-sub-text goods-opt"
          *ngFor="let opt of item.goodsOptList"
        >
          {{ opt.optTitle }}
          <div
            fxLayout="wrap"
            fxLayoutGap="8px"
            class="goods-opt-master"
            *ngFor="let mst of opt.goodsOptMasterList"
          >
            <span> + </span>
            <span
              fxFlex
              fxLayout
              fxLayoutAlign="space-between"
              fxLayoutGap="16px"
            >
              <span>{{ mst.goodsOptNm }}</span>
              <span>({{ mst.goodsOptAmt | currency }})</span>
            </span>
          </div>
        </span>

        <span class="product-price"> {{ getGoodsAmt(item) | currency }}</span>

        <div class="product-amount-wrap">
          <mat-icon
            class="amount-icon"
            [ngClass]="{ disable: item.goodsCnt === 1 }"
            (click)="onItemMinusBtnClick(i)"
            >remove</mat-icon
          >

          <span class="amount-text">{{ item.goodsCnt }}</span>

          <mat-icon class="amount-icon" (click)="onItemPlusBtnClick(i)"
            >add</mat-icon
          >
        </div>
      </div>
    </div>

    <div spaecer></div>

    <div class="content-wrap">
      <div class="content-order-wrap ordr">
        <div class="content-sub-title">{{ 'ordrInfo' | translate }}</div>

        <div>
          <div class="order-text-wrap">
            <span class="order-text">
              {{ userInfo?.familyName }} {{ userInfo?.firstName }}
              <ng-container
                *ngIf="userInfo?.familyName2 || userInfo?.firstName2"
              >
                ({{ userInfo?.familyName2 }} {{ userInfo?.firstName2 }})
              </ng-container>
            </span>
          </div>

          <div class="order-text-wrap" *ngIf="type === 'delivery'">
            <span class="order-text">{{ addr }}</span>

            <!-- <img src="/assets/img/order-info-btn.svg" (click)="onAddrClick()" /> -->
          </div>
        </div>

        <div class="order-request-wrap">
          <div class="request-sub-title">{{ 'mobileNum' | translate }}</div>

          <input
            formControlName="mobileNum"
            type="tel"
            class="request-input-text"
            mask="999999999999"
            [placeholder]="mobileNum || ('MSG.mobileNum' | translate)"
          />
        </div>
      </div>

      <div class="content-order-wrap">
        <div class="content-sub-title large">{{ 'reqCn' | translate }}</div>

        <div class="order-request-wrap">
          <div class="request-sub-title">
            {{ 'ordrReqCn' | translate }}
          </div>

          <input
            formControlName="ordrReqCn"
            type="text"
            class="request-input-text"
            [placeholder]="'MSG.askOrdrReqCn' | translate"
          />
        </div>

        <div class="order-request-wrap" *ngIf="!isDelivery">
          <div class="request-sub-title">{{ 'ordrRsrvDttm' | translate }}</div>

          <div fxLayoutGap="8px" [formGroup]="rsrvDttmFormGroup">
            <div fxFlex class="request-input-text dt" (click)="picker.open()">
              {{ rsrvDttmFormGroup.value.dt | date: 'yyyy-MM-dd' }}
              <input
                formControlName="dt"
                [matDatepicker]="picker"
                [min]="minDt"
              />
              <mat-datepicker #picker></mat-datepicker>
            </div>
            <input
              fxFlex
              formControlName="tm"
              type="time"
              class="request-input-text"
            />
          </div>
        </div>

        <div class="order-request-wrap" *ngIf="isDelivery">
          <div class="request-sub-title">{{ 'delivReqCn' | translate }}</div>

          <div class="request-select-wrap">
            <select
              formControlName="delivReqCnType"
              id="select"
              class="request-select-box"
            >
              <option [value]="'MSG.comeSafe' | translate">
                {{ 'MSG.comeSafe' | translate }}
              </option>
              <option [value]="'MSG.leaveItAtDoor' | translate">
                {{ 'MSG.leaveItAtDoor' | translate }}
              </option>
              <option value="">{{ 'directInput' | translate }}</option>
            </select>

            <!-- <label for="select">
              <mat-icon class="arrow-down">keyboard_arrow_down</mat-icon>
            </label> -->
          </div>

          <input
            *ngIf="!form.controls.delivReqCnType.value"
            formControlName="delivReqCn"
            type="text"
            class="request-input-text"
            [placeholder]="'MSG.askDelivReqCn' | translate"
          />
        </div>
      </div>
    </div>

    <div spacer></div>

    <div class="content-wrap">
      <div class="content-order-wrap">
        <div class="content-sub-title">{{ 'payMethod' | translate }}</div>

        <mat-radio-group formControlName="payType">
          <!-- TODO: payment -->
          <!-- <ng-container *ngIf="!isProd">
            <mat-radio-button
              name="payMethod"
              class="content-btn-wrap"
              value="PG_PAY"
              >{{ 'creditCard' | translate }}</mat-radio-button
            >
            <mat-radio-button
              name="payMethod"
              class="content-btn-wrap"
              value="LINE"
              >{{ 'linePay' | translate }}</mat-radio-button
            >
          </ng-container> -->
          <mat-radio-button
            name="payMethod"
            class="content-btn-wrap"
            value="DFPAY"
            >{{ 'dfPay' | translate }}</mat-radio-button
          >
        </mat-radio-group>
      </div>

      <div separator></div>

      <div class="coupon-title-wrap" (click)="onCpnClick()">
        <span class="coupon-title">{{ 'coupon' | translate }}</span>

        <div class="coupon-text-wrap">
          <span class="coupon-text">{{ getCpnAmt(cpn) | currency }}</span>

          <mat-icon class="arrow-right">keyboard_arrow_right</mat-icon>
        </div>
      </div>

      <div separator></div>

      <div class="order-order-wrap">
        <div class="request-sub-title">{{ 'point' | translate }}</div>
        <div fxLayout fxLayoutGap="8px">
          <input
            type="number"
            placeholder="0"
            class="request-input-text"
            formControlName="usePointAmt"
            [max]="pointAmt"
            (change)="onPointChange()"
          />
          <button
            mat-flat-button
            class="use-all-button"
            fxFlex="88px"
            (click)="onUseAllPointClick()"
          >
            {{ 'useAll' | translate }}
          </button>
        </div>
        <p class="pay-text">
          {{ 'totalPointAmt' | translate }} : {{ pointAmt | number }}P
        </p>
      </div>
    </div>

    <div spacer></div>

    <div class="content-wrap">
      <div class="text-wrap">
        <div class="pay-text-wrap">
          <span class="pay-text">{{ 'ordrAmt' | translate }}</span>
          <span class="pay-text"> {{ getCartAmt() | currency }}</span>
        </div>

        <div class="pay-text-wrap" *ngIf="isDelivery">
          <span class="pay-text">{{ 'delivFee' | translate }}</span>
          <span class="pay-text"> {{ getDelivAmt() | currency }}</span>
        </div>

        <div class="pay-text-wrap">
          <span class="pay-text">{{ 'coupon' | translate }}</span>
          <span class="pay-text">- {{ getCpnAmt(cpn) | currency }}</span>
        </div>

        <div class="pay-text-wrap">
          <span class="pay-text">{{ 'point' | translate }}</span>
          <span class="pay-text"
            >- {{ form.get('usePointAmt').value | currency }}</span
          >
        </div>
      </div>
      <div separator></div>
      <div class="total-text-wrap">
        <span class="total-text">{{ 'totalPayAmt' | translate }}</span>
        <span class="total-text price"> {{ getPayAmt() | currency }}</span>
      </div>
    </div>

    <div spacer></div>

    <div class="content-wrap" (click)="onWarnClick()">
      <div class="warn-subTitle-wrap">
        <span class="warn-sub-title">{{ 'cautions' | translate }}</span>

        <mat-icon class="arrow-down-icon" [ngClass]="{ on: isWarn }"
          >keyboard_arrow_down</mat-icon
        >
      </div>

      <span class="warn-sub-text" [ngClass]="{ on: isWarn }">
        個人情報保護方針と利用規約に同意して注文
        <!-- {{ 'MSG.agreeToPay' | translate }} -->
      </span>
    </div>
  </div>

  <div class="pay-wrapper">
    <button
      mat-flat-button
      round
      color="primary"
      class="pay-button"
      (click)="onPayClick()"
    >
      {{ getPayAmt() | currency }} {{ 'orderConfirm' | translate }}
    </button>
  </div>
</ng-container>

<ng-template #noCartData>
  <div>
    <div class="empty-container">
      <mat-icon class="error-icon">shopping_cart</mat-icon>
      <div class="empty-text">{{ 'MSG.cartIsEmpty' | translate }}</div>
    </div>
  </div>
</ng-template>
