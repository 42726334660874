import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import Editor, { EditorCore } from '@toast-ui/editor';
import colorPlugin from '@toast-ui/editor-plugin-color-syntax';
import { HookCallback, Viewer } from '@toast-ui/editor/types/editor';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImageService } from '../../services/image.service';
import { IEditorConfig } from './pw-tui-editor.config';

@Component({
  selector: 'pw-tui-editor',
  templateUrl: './pw-tui-editor.component.html',
  styleUrls: ['./pw-tui-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => PwTuiEditorComponent),
    },
  ],
})
export class PwTuiEditorComponent
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor
{
  /**
   * Editor 설정
   */
  @Input() config: IEditorConfig = {};

  /**
   * Editor 값
   */
  @Input() value: string;

  /**
   * 비활성화
   */
  @Input() disabled: boolean;

  /**
   * 파일 업로드 경로. 서버에 file 키로 요청을 하면, 응답의 url키에 파일 경로가 있어야 한다
   */
  @Input() uploadUrl: string;

  /**
   * 첨부 이미지 최대 폭(px). 지정하면 이 폭 초과하는 이미지를 첨부할 때 이 폭으로 리사이징 한다
   */
  @Input() maxImgWidth: number;

  /**
   * 다크 모드 사용
   */
  @Input() darkMode: boolean;

  /**
   * 컨텐츠 형식
   *
   * 값 업데이트 시 사용할 파싱 방법
   *
   * markdown에서 html도 같이 지원하나, 문제 발생 시 타입 변경하여 사용
   * @default markdown
   */
  @Input() contentType: 'markdown' | 'html' = 'markdown';

  @Output() init: EventEmitter<EditorCore | Viewer> = new EventEmitter();

  private editorInstance: EditorCore | Viewer;

  private onChange: any;

  private onTouched: any;

  private tmpConfig: IEditorConfig = {};

  // 로드할 언어 코드 + 로딩 중 작업
  private static loadedLangs: Map<string, Promise<void>> = new Map();

  constructor(
    private zone: NgZone,
    private injector: Injector,
    private elementRef: ElementRef,
    private renderer2: Renderer2,
    private http: HttpClient,
    private imageService: ImageService
  ) {}

  ngOnInit(): void {
    this.initEditor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.value) {
      this.setContent(changes.value.currentValue);
    }

    if (changes?.config) {
      this.initEditor();
    }
  }

  ngOnDestroy(): void {
    this.editorInstance?.destroy();
  }

  getInstance(): EditorCore | Viewer {
    return this.editorInstance;
  }

  writeValue(obj: string): void {
    this.setContent(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.initEditor();
  }

  setContent(content: string): void {
    this.value = content;

    if (this.editorInstance instanceof EditorCore) {
      if (this.contentType === 'markdown' && this.editorInstance.setMarkdown) {
        this.editorInstance.setMarkdown(this.value);
      } else {
        this.editorInstance.setHTML(this.value);
      }
    } else if (this.editorInstance) {
      if (
        this.contentType === 'markdown' &&
        (this.editorInstance as any).preview.setMarkdown
      ) {
        (this.editorInstance as any).preview.setMarkdown(this.value);
      } else {
        (this.editorInstance as any).preview.setHTML(this.value);
      }
    }
  }

  /**
   * tui Editor 초기 설정
   */
  private initEditor(): void {
    // height 고정 설정
    const thisEle = this.elementRef.nativeElement as HTMLElement;
    thisEle.style.height = this.config.height ?? '500px';
    thisEle.style.overflowY = this.disabled ? 'scroll' : null;

    this.tmpConfig = this.config;
    this.tmpConfig.usageStatistics = false;
    this.tmpConfig.hooks = {
      addImageBlobHook: (blob: Blob | File, callback: HookCallback) => {
        this.addImageBlob(blob, callback);
      },
    };
    if (this.config.plugins) {
      this.tmpConfig.plugins = this.config.plugins;
      if (!this.config.plugins.includes(colorPlugin)) {
        this.tmpConfig.plugins.push(colorPlugin);
      }
    } else {
      this.tmpConfig.plugins = [colorPlugin];
    }
    this.tmpConfig.theme = this.darkMode ? 'dark' : 'light';
    // 번역 조회 후 에디터 생성
    this.initLang$().finally(() => {
      this.zone.runOutsideAngular(() => {
        this.tmpConfig.events = {};
        this.config.events = this.config.events ?? {};
        const { load } = this.config.events;
        this.tmpConfig.events.load = (editor) => {
          this.init.emit(editor);
          if (load) {
            load(editor);
          }
        };
        const { change } = this.config.events;
        this.tmpConfig.events.change = (type) => {
          if (this.onChange && this.editorInstance instanceof EditorCore) {
            this.zone.run(() => {
              this.onChange((this.editorInstance as EditorCore).getHTML());
            });
          }
          if (change) {
            change(type);
          }
          this.value = (this.editorInstance as EditorCore).getHTML();
        };
        const { blur } = this.config.events;
        this.tmpConfig.events.blur = (type) => {
          if (this.onTouched) {
            this.zone.run(() => {
              this.onTouched();
            });
          }
          if (blur) {
            blur(type);
          }
        };

        // 에디터 버튼 텍스트가 값으로 설정되지 않도록 기존 에디터 삭제
        thisEle.innerHTML = '';

        // Angular 의존성 사용하기 위해 Element에 Injector 추가
        this.editorInstance = Editor.factory({
          el: Object.assign(this.elementRef.nativeElement, {
            ngInjector: this.injector,
          }),
          height: this.tmpConfig.height ?? '500px',
          initialEditType: 'wysiwyg',
          hideModeSwitch: true,
          language: this.getBrowserCultureLang(),
          initialValue: this.value ?? '',
          viewer: this.disabled,
          ...this.tmpConfig,
        });
      });
    });
  }

  /**
   * 에디터 파일 업로드
   */
  private addImageBlob(blob: Blob | File, callback: HookCallback) {
    if (!this.uploadUrl) {
      // 이미지 파일서버 경로 설정하지 않았을 때. 파일을 base64로 본문에 직접 삽입한다
      // 운영환경에서도 작동하긴 하나, DB 부하와 네트워크 최적화를 위해 개발환경에서만 사용한다
      const reader = new FileReader();
      reader.readAsDataURL(blob);

      reader.onload = () => {
        if (this.maxImgWidth) {
          this.imageService
            .resizeImageSmall(reader.result, this.maxImgWidth)
            .then((img) => {
              callback(img);
            });
        } else {
          callback(reader.result as string);
        }
      };
    } else {
      this.uploadImage$(blob).subscribe((res) => {
        callback(res.url);
      });
    }
  }

  /**
   * 이미지 파일 서버 전송 로직
   */
  private uploadImage$(file: Blob | File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, (file as any).name);
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    return this.http
      .post<any>(this.uploadUrl, formData, { headers })
      .pipe(map((res: { url: string }) => res));
  }

  private initLang$(): Promise<any> {
    const lang = this.getBrowserCultureLang().toLowerCase();

    // 이미 로드되거나 로딩 중인 언어인지 확인
    if (!PwTuiEditorComponent.loadedLangs.has(lang)) {
      const i18nScript: HTMLScriptElement =
        this.renderer2.createElement('script');
      i18nScript.type = 'text/javascript';
      i18nScript.src = `https://uicdn.toast.com/editor/latest/i18n/${lang}.js`;

      // 언어 설정을 위해 임시로 window에 정의
      Object.assign(window, { toastui: { Editor } });

      // 언어 설정 스크립트 추가
      this.renderer2.appendChild(
        document.getElementsByTagName('head')[0],
        i18nScript
      );
      const promise = new Promise<void>((res) => {
        const { setLanguage } = Editor;
        Editor.setLanguage = (code: string, data: Record<string, string>) => {
          setLanguage(code, data);
          setTimeout(() => {
            // 언어 설정 스크립트 삭제
            this.renderer2.removeChild(
              document.getElementsByTagName('head')[0],
              i18nScript
            );
            // 임시로 window에 정의한 Editor 삭제
            delete (window as any).toastui;
            Editor.setLanguage = setLanguage;
            res(null);
          });
        };
      });

      // 로드된 언어 셋에 추가
      PwTuiEditorComponent.loadedLangs.set(lang, promise);
      return promise;
    }
    // 현재 로딩 중인 작업 반환
    return PwTuiEditorComponent.loadedLangs.get(lang);
  }

  private getBrowserCultureLang() {
    if (
      typeof window === 'undefined' ||
      typeof window.navigator === 'undefined'
    ) {
      return undefined;
    }
    let browserCultureLang = window.navigator.languages
      ? window.navigator.languages[0]
      : null;
    browserCultureLang = browserCultureLang || window.navigator.language;
    return browserCultureLang;
  }

  /* private _isDarkMode(): boolean {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  } */
}
