import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Cpn } from 'src/app/model/cpn/cpn';
import { CpnService } from 'src/app/repository/cpn/cpn.service';
import { CartGoods, CartService } from 'src/app/services/cart.service';
import { CouponDetailComponent } from '../coupon-detail/coupon-detail.component';
import { DialogService } from '../dialog/dialog.service';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
})
export class CouponComponent implements OnInit {
  userInfoId: number;

  cpnList$: Observable<Cpn[]>;

  isFromCart = false;

  selectedCpn: Cpn;

  ordrType: 'delivery' | 'takeout';

  get cartData(): CartGoods[] {
    if (this.ordrType === 'takeout') {
      return this.cartService.cartData;
    }
    if (this.ordrType === 'delivery') {
      return this.cartService.cartData2;
    }
    return [];
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private matDialogData: any,
    @Optional() private matDialogRef: MatDialogRef<CouponComponent>,
    public cpnService: CpnService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private cartService: CartService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.userInfoId = this.authService.loginInfo.id;
    this.isFromCart = this.matDialogData?.type === 'cart';
    this.ordrType = this.matDialogData?.ordrType;
    this.selectedCpn = this.matDialogData?.cpn;
    this.initCpn();

    if (this.selectedCpn) {
      this.showHowToDeselectCpn();
    }
  }

  onBackClick(): void {
    if (!this.isFromCart) {
      return;
    }

    this.matDialogRef.close();
  }

  onCpnClick(cpn: Cpn): void {
    if (this.isFromCart) {
      if (!this.hasCpnGoodsInCart(cpn)) {
        return;
      }

      if (this.selectedCpn?.id === cpn?.id) {
        this.selectedCpn = null;
      } else {
        this.selectedCpn = cpn;
      }

      this.matDialogRef.close(this.selectedCpn);
      return;
    }

    this.dialogService.matDialog.open(CouponDetailComponent, {
      panelClass: 'fullscreen-modal',
      data: { cpn },
      hasBackdrop: false,
    });
  }

  hasCpnGoodsInCart({ cpnMaster }: Partial<Cpn>): boolean {
    if (!this.isFromCart) {
      return true;
    }

    if (!cpnMaster?.goodsId) {
      return true;
    }

    return this.cartData.some((cartGoods) => {
      return cartGoods.goodsId === cpnMaster?.goodsId;
    });
  }

  private initCpn(): void {
    this.cpnList$ = this.cpnService.list$.pipe(map(({ content }) => content));
    this.cpnService.getPage({
      userInfoId: this.userInfoId,
      cpnSttsType: 'AVAIL',
    });
  }

  private showHowToDeselectCpn(): void {
    this.dialogService.snackbar(
      this.translateService.instant('MSG.howToDeselectCpn')
    );
  }
}
