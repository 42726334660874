<div class="header-wrapper" [style.height.px]="height">
  <div
    fxLayoutAlign="center center"
    class="header-left"
    #left
    [fxFlex]="titlePaddingLevel * titlePaddingUnit + 'px'"
  >
    <ng-content select="[left]"></ng-content>
    <mat-icon (click)="onBackClick()" *ngIf="hasBack">chevron_left</mat-icon>
  </div>
  <div fxFlex="grow" fxLayoutAlign="center center" class="header-center">
    <div class="header-center-title"><ng-content></ng-content></div>
  </div>
  <div
    fxLayoutAlign="center center"
    class="header-right"
    #right
    [fxFlex]="titlePaddingLevel * titlePaddingUnit + 'px'"
  >
    <ng-content select="[right]"></ng-content>
  </div>
</div>
<div class="header-spacer" [style.padding-top.px]="height"></div>
