<div class="goods-opt-master-container" *ngIf="type">
  <div fxLayout fxLayoutGap="10px">
    <span class="goods-opt-title">{{ goodsOpt.optTitle }}</span>
    <span
      class="goods-opt-desc"
      [class.opt-error]="formControl.invalid"
      *ngIf="desc"
    >
      ({{ desc }})
    </span>
  </div>

  <div class="goods-opt-master-list">
    <mat-radio-group *ngIf="type === 'radio'">
      <mat-radio-button
        [value]="goodsOptMaster.id"
        [checked]="isFirst"
        (change)="onRadioChange($event)"
        *ngFor="
          let goodsOptMaster of goodsOpt.goodsOptMasterList;
          first as isFirst
        "
      >
        <div
          class="goods-opt-master-label"
          fxLayout
          fxLayoutAlign="space-between center"
        >
          <span class="goods-opt-master-nm">
            {{ goodsOptMaster.goodsOptNm }}
          </span>
          <span class="goods-opt-master-amt" *ngIf="goodsOptMaster.goodsOptAmt">
            (
            {{ goodsOptMaster.goodsOptAmt >= 0 ? '+' : '-' }}
            {{ goodsOptMaster.goodsOptAmt | currency }}
            )
          </span>
        </div>
      </mat-radio-button>
    </mat-radio-group>

    <ng-container *ngIf="type === 'checkbox'">
      <mat-checkbox
        [disabled]="isCheckboxDisabled(goodsOptMaster.id)"
        (change)="onCheckboxChange($event, goodsOptMaster.id)"
        *ngFor="let goodsOptMaster of goodsOpt.goodsOptMasterList; index as i"
      >
        <div
          class="goods-opt-master-label"
          fxLayout
          fxLayoutAlign="space-between center"
        >
          <span class="goods-opt-master-nm">
            {{ goodsOptMaster.goodsOptNm }}
          </span>

          <span class="goods-opt-master-amt" *ngIf="goodsOptMaster.goodsOptAmt">
            (
            {{ goodsOptMaster.goodsOptAmt >= 0 ? '+' : '-' }}
            {{ goodsOptMaster.goodsOptAmt | currency }}
            )
          </span>
        </div>
      </mat-checkbox>
    </ng-container>
  </div>
</div>
