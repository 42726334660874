import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';
import { JoyfoodHeaderModule } from '../joyfood-header/joyfood-header.module';
import { AlertComponent } from './alert/alert.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { LoginComponent } from './login/login.component';
import { MenuSelectComponent } from './menu-select/menu-select.component';
import { ResetPwComponent } from './reset-pw/reset-pw.component';
import { TakeoutComponent } from './takeout/takeout.component';

@NgModule({
  declarations: [
    AlertComponent,
    ConfirmComponent,
    TakeoutComponent,
    DeliveryComponent,
    LoginComponent,
    ResetPwComponent,
    MenuSelectComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    TranslateModule,
    JoyfoodHeaderModule,
    PipesModule,
  ],
  exports: [
    MatDialogModule,
    MatSnackBarModule,
    AlertComponent,
    ConfirmComponent,
    TakeoutComponent,
    DeliveryComponent,
    LoginComponent,
    MenuSelectComponent,
  ],
})
export class DialogModule {}
