import { AnimationEvent } from '@angular/animations';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService, StatusBarService, StorageService } from 'pw-lib';
import { environment } from 'src/environments/environment';
import { DialogService } from './components/dialog/dialog.service';
import { fadeAnimation } from './core/animation';
import { AppUpdateService } from './core/services/app-update.service';
import { RouteHelperService } from './core/services/route-helper.service';
import { Utils } from './core/utils';
import { GoodsDeliveryService } from './repository/goods/goods-delivery.service';
import { GoodsGrpService } from './repository/goods/goods-grp.service';
import { GoodsMenuService } from './repository/goods/goods-menu.service';
import { GoodsNewService } from './repository/goods/goods-new.service';
import { GoodsRcmdService } from './repository/goods/goods-rcmd.service';
import { GoodsTakeoutService } from './repository/goods/goods-takeout.service';
import { LanguageService } from './services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
})
export class AppComponent implements OnInit {
  /**
   * 하드웨어 백버튼 조작시 앱 종료하는 페이지 목록
   */
  APP_EXIT_PATH_LIST = ['/main/home', '/main/coupon', '/main/new', '/main/my'];

  /**
   * 뒤로가기 2번 체크
   */
  shouldExitApp = false;

  /**
   * 라우팅 애니메이션 여부
   */
  shouldRouteAnimate = true;

  lastGoodsUpdateTime: number;

  get isTest(): boolean {
    return environment.name !== 'prod';
  }

  constructor(
    public routeHelperService: RouteHelperService,
    private renderer2: Renderer2,
    private appService: AppService,
    private statusBarBrightness: StatusBarService,
    private translateService: TranslateService,
    private appUpdateService: AppUpdateService,
    private languageService: LanguageService,
    private dialogService: DialogService,
    private storageService: StorageService,
    private goodsGrpService: GoodsGrpService,
    private goodsTakeoutService: GoodsTakeoutService,
    private goodsDeliveryService: GoodsDeliveryService,
    private goodsMenuService: GoodsMenuService,
    private goodsNewService: GoodsNewService,
    private goodsRcmdService: GoodsRcmdService
  ) {}

  ngOnInit(): void {
    if (environment.production) {
      this.languageService.changeLang('ja');
    } else {
      // this.languageService.changeLang('ko');
      this.languageService.changeLang('ja');
    }

    this.appUpdateService.init();
    this.initApp();
    this.getApiData();
    this.initInterval();
  }

  onAnimationStart(e: AnimationEvent): void {
    this.routeHelperService.animationsStart(e);
  }

  /**
   * 라우팅 애니메이션 종료시
   */
  onAnimationsDone(e: AnimationEvent): void {
    this.routeHelperService.animationsDone(e);
  }

  /**
   * 앱 화면 전체를 사용할 수 있도록 설정하고, 상태바 아이콘을 밝게 설정한다
   */
  private initApp(): void {
    if (!Utils.isApp()) {
      // 앱 아니면 종료
      return;
    }

    this.renderer2.addClass(document.body, 'mobile-app');

    // SafeArea 활성화
    this.appService.useSafeArea(true);

    this.statusBarBrightness.setStatusBarBrightness('dark');

    // 기본 뒤로가기 처리 비활성화
    this.appService.useDefaultBackHandler(false);

    // 하드웨어 백버튼(안드로이드) 이벤트
    this.appService.addBackPressed(() => {
      // 열려있는 다이얼로그가 있다면 마지막 요소 닫기
      const { openDialogs } = this.dialogService.matDialog;
      if (openDialogs?.length) {
        const lastDialog = openDialogs.pop();
        lastDialog.close();
        return;
      }

      if (
        ['/', ...this.APP_EXIT_PATH_LIST].indexOf(
          this.routeHelperService.currentPath
        ) < 0
      ) {
        // 루트가 아니면 뒤로가기
        window.history.back();
        return;
      }

      if (this.shouldExitApp) {
        // 한번 더 뒤로가기 하면 종료
        this.appService.exit();
        return;
      }

      const matSnackBar = this.dialogService.matSnackBar.open(
        this.translateService.instant('MSG.askAppExit'),
        null,
        {
          // 2초간 뒤로가기 체크
          duration: 2000,
        }
      );

      // dismiss 전에 다시 누르면 종료
      matSnackBar.afterOpened().subscribe(() => {
        this.shouldExitApp = true;
      });

      // 이후면 초기화
      matSnackBar.afterDismissed().subscribe(() => {
        this.shouldExitApp = false;
      });
    });
  }

  private getApiData(): any {
    const currentMs = new Date().valueOf();
    this.storageService.set('lastGoodsUpdateTime', currentMs);
    this.lastGoodsUpdateTime = currentMs;

    this.goodsGrpService.getPage({ activeFl: true, sort: 'sort,asc' }, true);
    this.goodsTakeoutService.getPage({ size: 1000 }, true);
    this.goodsDeliveryService.getPage({ size: 1000 }, true);
    this.goodsMenuService.getPage({ size: 1000 }, true);
    this.goodsNewService.getPage({ size: 1000 }, true);
    this.goodsRcmdService.getPage({ size: 1000 }, true);
  }

  private initInterval(): void {
    if (!this.lastGoodsUpdateTime) {
      this.getApiData();
    }

    setInterval(() => {
      const diffMs = new Date().valueOf() - this.lastGoodsUpdateTime;
      const diffMin = diffMs / 1000 / 60;

      // 운영은 120분 이상 전에 받아온 상품 갱신
      if (environment.name === 'prod' && diffMin < 120) {
        return;
      }

      // 비 운영은 10분 이상 전에 받아온 상품 갱신
      if (environment.name !== 'prod' && diffMin < 10) {
        return;
      }

      this.getApiData();
      // 1분마다 검사
    }, 1000 * 60);
  }
}
