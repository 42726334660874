import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MapGeocoder, MapGeocoderResponse } from '@angular/google-maps';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize, tap } from 'rxjs';
import { AddressHist } from 'src/app/services/hist-storage.service';
import { DialogService } from '../dialog/dialog.service';
import { GoogleMapComponent } from '../google-map/google-map.component';

@Component({
  selector: 'app-address-finder',
  templateUrl: './address-finder.component.html',
  styleUrls: ['./address-finder.component.scss'],
})
export class AddressFinderComponent implements OnInit {
  @ViewChild(GoogleMapComponent) googleMapComponent: GoogleMapComponent;

  options: google.maps.MapOptions = {
    gestureHandling: 'greedy',
    scrollwheel: false,
    keyboardShortcuts: false,
    controlSize: 24,
  };

  initCenter: any;

  currentCenter: any;

  address: string;

  detail = new FormControl(null, Validators.required);

  isGeocodeLoading = false;

  /**
   * 지도 api 과다 호출을 막기 위한 지연 실행자
   */
  delayedFunction: () => void;

  /**
   * 지도 api 과다 호출을 막기 위한 실행 예약
   */
  timeout: any;

  constructor(
    private matDialogRef: MatDialogRef<AddressFinderComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private mapGeocoder: MapGeocoder,
    private dialogService: DialogService
  ) {
    this.initCenter = { lat: dialogData.lat, lng: dialogData.lng };
    this.currentCenter = this.initCenter;
  }

  ngOnInit(): void {}

  onMapLoad(): void {
    this.setAddress({ ...this.initCenter });
  }

  /**
   * dragend시 api 호출
   *
   * 과도한 api 호출을 막기 위해 1초 뒤에 실행되도록 예약하고 그동안 새 요청이 발생하면 기존 요청을 덮어씌움
   */
  onMapDragend(center: google.maps.LatLng): void {
    this.isGeocodeLoading = true;

    // 로딩중이거나 지연 실행자 없는 경우
    if (this.isGeocodeLoading || !this.delayedFunction) {
      // 기존 실행 예약 초기화
      clearTimeout(this.timeout);
      // 지연 실행자 설정
      this.delayedFunction = () => {
        this.setAddress(center);
      };
    }

    // 지연실행자가 설정되어있다면
    if (this.delayedFunction) {
      // 1초 뒤 실행 예약
      this.timeout = setTimeout(() => {
        this.delayedFunction();
      }, 1000);
    }
  }

  onBackClick(): void {
    this.matDialogRef.close();
  }

  onOkClick(): void {
    if (this.detail.invalid) {
      this.dialogService.alert('MSG.inputDetailAddr').subscribe();
      this.detail.markAsTouched();
      return;
    }

    const addressHist: AddressHist = {
      address: `${this.address}, ${this.detail.value}`,
      ...this.currentCenter,
    };

    this.matDialogRef.close(addressHist);
  }

  private setAddress(location: google.maps.LatLng): void {
    this.mapGeocoder
      .geocode({
        location,
      })
      .pipe(
        tap((mapGeocoderResponse: MapGeocoderResponse) => {
          this.address = mapGeocoderResponse.results[0].formatted_address;
          const { lat, lng } = mapGeocoderResponse.results[0].geometry.location;
          this.currentCenter = { lat: lat(), lng: lng() };
        }),
        finalize(() => {
          this.isGeocodeLoading = false;
          this.delayedFunction = null;
        })
      )
      .subscribe();
  }
}
