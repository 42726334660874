<div *ngIf="apiLoaded | async">
  <google-map
    width="100%"
    height="100%"
    [center]="center"
    [zoom]="zoom"
    [options]="options"
    (mapClick)="onMapclick()"
    (mapDragend)="onMapDragend()"
  >
    <map-marker
      *ngFor="let markerPosition of markers; index as i"
      [position]="markerPosition"
      [options]="markerOptions"
    ></map-marker>
    <map-circle [options]="circleOptions"></map-circle>
  </google-map>
</div>
