import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapService {
  /** google maps api 가 로드되었는지 상태 확인 */
  get apiLoaded(): Observable<boolean> {
    return this.apiLoadedSubject.asObservable();
  }

  private apiLoadedSubject: ReplaySubject<boolean> = new ReplaySubject(1);

  private readonly key = 'AIzaSyBi3XSoYzMevRu4WeYAG8Kd-j7JuT3pTnY';

  constructor(
    private httpClient: HttpClient,
    private langService: LanguageService
  ) {
    // API lazyload
    this.httpClient
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${this.key}&language=${this.langService.lang}`,
        'callback'
      )
      .subscribe({
        next: () => {
          this.apiLoadedSubject.next(true);
          this.apiLoadedSubject.complete();
        },
        error: (error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          this.apiLoadedSubject.next(false);
          this.apiLoadedSubject.complete();
        },
      });
  }
}
