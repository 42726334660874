import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PageRepositoryService } from '../abstract-repository.service';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService extends PageRepositoryService<any> {
  protected baseUri = 'api/userInfo';

  constructor(protected http: HttpClient) {
    super(http);
  }

  updatePw(id: number, body: { userPw: string }): Observable<any> {
    return this.http.put(
      `${environment.apiServerUrl}/${this.baseUri}/updatePw/${id}`,
      body
    );
  }
}
