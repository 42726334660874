import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { StorageService } from 'projects/pw-lib/src/public-api';
import {
  EMPTY,
  Observable,
  catchError,
  firstValueFrom,
  forkJoin,
  map,
  mergeMap,
  of,
  tap,
  throwError,
} from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Cpn } from 'src/app/model/cpn/cpn';
import { Delng } from 'src/app/model/delng/delng';
import { DelngGoods } from 'src/app/model/delng/delng-goods';
import { DelngGoodsOpt } from 'src/app/model/delng/delng-goods-opt';
import { DelngGoodsOptMaster } from 'src/app/model/delng/delng-goods-opt-master';
import { Payment } from 'src/app/model/delng/payment';
import { Gcct } from 'src/app/model/gcct/gcct';
import { Mrhst } from 'src/app/model/mrhst/mrhst';
import { PgPayResult } from 'src/app/model/pg-pay/pg-pay-result';
import { DelngService } from 'src/app/repository/delng/delng.service';
import { DelngOfferType } from 'src/app/repository/enumerations/delng-offer-type.model';
import { DelngType } from 'src/app/repository/enumerations/delng-type.model';
import { UserInfoService } from 'src/app/repository/user/user-info.service';
import { CartGoods, CartService } from 'src/app/services/cart.service';
import { EpsilonPgService } from 'src/app/services/epsilon-pg.service';
import { HistStorageService } from 'src/app/services/hist-storage.service';
import { environment } from 'src/environments/environment';
import { GoodsService } from '../../repository/goods/goods.service';
import { CouponComponent } from '../coupon/coupon.component';
import { DialogService } from '../dialog/dialog.service';
import { isMrhstOpen } from '../goods/goods.component';
import { PaymentComponent } from '../payment/payment.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  form: FormGroup = this.formBuilder.group({
    mobileNum: [
      this.storageService.get('mobileNum') ||
        this.authService.loginInfo.mobileNum,
    ],
    ordrReqCn: [null],
    rsrvDttm: [null],
    delivReqCnType: [this.translateService.instant('MSG.comeSafe')],
    delivReqCn: [null],
    // payType: ['PG_PAY'],
    // payType: [this.isProd ? 'DFPAY' : 'PG_PAY'],
    payType: ['DFPAY'],
    receiptMobileNum: [null],
    usePointAmt: [0],
  });

  rsrvDttmFormGroup: FormGroup = this.formBuilder.group({
    dt: [dayjs().format('YYYY-MM-DD')],
    tm: [dayjs().add(30, 'm').format('HH:mm')],
  });

  minDt = dayjs().format('YYYY-MM-DD');

  /**
   * 주의사항
   */
  isWarn = true;

  /**
   * 쿠폰
   */
  cpn: Cpn;

  /**
   * 선물
   */
  gcct: Gcct;

  mrhst: Mrhst;

  addr: string;

  // delivAmtMap: Map<string, MrhstDelivAmt> = new Map();

  type: 'delivery' | 'takeout';

  userInfo: any;

  pointAmt = 0;

  dfPayAmt = 0;

  userCardId = null;

  mobileNum: string;

  get isDelivery(): boolean {
    return this.type === 'delivery';
  }

  get usablePoint(): number {
    if (this.getCpnAppliedAmt() + this.getDelivAmt() <= this.pointAmt) {
      return this.getCpnAppliedAmt() + this.getDelivAmt();
    }

    return this.pointAmt;
  }

  get cartData(): CartGoods[] {
    if (this.type === 'takeout') {
      return this.cartService.cartData;
    }
    if (this.type === 'delivery') {
      return this.cartService.cartData2;
    }
    return [];
  }

  set cartData(v: CartGoods[]) {
    if (this.type === 'takeout') {
      this.cartService.cartData = v;
    }
    if (this.type === 'delivery') {
      this.cartService.cartData2 = v;
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private matDialogData: any,
    private router: Router,
    private formBuilder: FormBuilder,
    private matDialogRef: MatDialogRef<CartComponent>,
    private translateService: TranslateService,
    private cartService: CartService,
    private storageService: StorageService,
    private dialogService: DialogService,
    private epsilonPgService: EpsilonPgService,
    private histStorageService: HistStorageService,
    private delngService: DelngService,
    private authService: AuthService,
    private goodsService: GoodsService,
    private userInfoService: UserInfoService
  ) {}

  ngOnInit(): void {
    this.userInfo = this.authService.loginInfo;
    this.mobileNum = this.userInfo.mobileNum;
    this.mrhst = this.matDialogData.mrhst;
    this.type = this.matDialogData.type;
    this.setAddr();
    // this.getMrhst();
    this.setUserInfo();
  }

  onBackClick(): void {
    this.matDialogRef.close();
  }

  /**
   * 주의사항 클릭
   */
  onWarnClick(): void {
    this.isWarn = !this.isWarn;
  }

  /**
   * 상품 삭제 버튼 클릭
   */
  onDeleteBtnClick(index: number): void {
    this.cartService.delItem([index], this.type);
  }

  /**
   * 상품 개수 1씩 감소 (최대 0개까지)
   */
  onItemMinusBtnClick(index: number): void {
    const data = this.cartData;
    if (data[index].goodsCnt > 1) data[index].goodsCnt -= 1;
    this.cartData = data;
    if (this.usablePoint < this.form.getRawValue().usePointAmt) {
      this.form.patchValue({ usePointAmt: 0 });
    }
  }

  /**
   * 상품 개수 1씩 증가
   */
  onItemPlusBtnClick(index: number): void {
    const data = this.cartData;
    data[index].goodsCnt += 1;
    this.cartData = data;
  }

  /**
   * 전체삭제 클릭
   */
  onAllDeleteClick(): void {
    this.cartData = null;
    this.matDialogRef.close();
  }

  /**
   * type에 따른 modal창 열기
   */
  onCpnClick(): any {
    this.dialogService.matDialog
      .open(CouponComponent, {
        panelClass: 'fullscreen-modal',
        data: { type: 'cart', ordrType: this.type, cpn: this.cpn },
      })
      .beforeClosed()
      .pipe(
        tap((cpn) => {
          this.cpn = cpn;

          // if (this.getPayAmt() < 0) {
          //   this.onUseAllPointClick();
          // }

          if (this.usablePoint < this.form.getRawValue().usePointAmt) {
            this.form.patchValue({ usePointAmt: 0 });
          }
        })
      )
      .subscribe();
  }

  /**
   * 사용 포인트 변경
   */
  onPointChange(): void {
    const control = this.form.get('usePointAmt');
    const usePointAmt = control.value;

    if (usePointAmt < this.usablePoint) {
      control.setValue(usePointAmt);
    } else {
      control.setValue(Math.max(this.usablePoint, 0));
    }
  }

  /**
   * 포인트 전부사용
   */
  onUseAllPointClick(): void {
    if (this.usablePoint > 0) {
      this.form.get('usePointAmt').setValue(this.usablePoint);
    }
  }

  /**
   * 상품 총 금액 반환
   */
  getCartAmt(): number {
    return this.cartService.getCartAmt(this.type);
  }

  getDelivAmt(): number {
    if (this.type !== 'delivery') {
      return 0;
    }

    if (this.getCartAmt() >= this.mrhst.mrhstOrdr.delivFreeStdAmt) {
      return 0;
    }

    return this.mrhst.mrhstOrdr.delivAmt || 0;
  }

  /**
   * 쿠폰 적용 금액
   */
  getCpnAppliedAmt(): number {
    return this.getCartAmt() - this.getCpnAmt(this.cpn);
  }

  /**
   * 쿠폰, 포인트 적용된 결제할 금액
   */
  getPayAmt(): number {
    return (
      this.getCpnAppliedAmt() +
      this.getDelivAmt() -
      this.form.get('usePointAmt').value
    );
  }

  /**
   * 쿠폰 금액
   */
  getCpnAmt(cpn: Cpn): number {
    if (!cpn) {
      return 0;
    }

    const { cpnMasterType, cpnAmt, cpnRatio, maxCpnAmt, goodsId } =
      cpn.cpnMaster;

    const [cpnGoods] = this.cartData.filter(
      (cartGoods) => cartGoods.goodsId === goodsId
    );

    if (cpnMasterType === 'RATIO') {
      if (cpnGoods) {
        return Math.floor((cpnGoods.goodsAmt / 100) * cpnRatio);
      }

      const amt = Math.floor(
        ((this.getCartAmt() + this.getDelivAmt()) / 100) * cpnRatio
      );
      return maxCpnAmt > 0 ? Math.min(amt, maxCpnAmt) : amt;
    }

    if (cpnMasterType === 'AMT') {
      return cpnAmt;
    }

    return 0;
  }

  // /**
  //  * 상품권 금액
  //  */
  // getGcctAmt(gcct: Gcct): number {
  //   if (!gcct) return 0;
  //   const { goods } = gcct;
  //   if (!goods) return null;
  //   if (goods.goodsAmt) return goods.goodsAmt;
  //   if (goods.goodsOptList?.length) {
  //     const requiredList = goods.goodsOptList.filter(
  //       (value) => value.requiredFl
  //     );
  //     if (requiredList.length) {
  //       const [required] = requiredList;
  //       const [optMaster] = required.goodsOptMasterList.map(
  //         (opt) => opt.goodsOptAmt
  //       );
  //       return optMaster;
  //     }
  //   }
  //   return null;
  // }

  /**
   * 상품 금액
   */
  getGoodsAmt(item: CartGoods): number {
    let amt = 0;
    amt += item.goodsAmt * item.goodsCnt;
    if (item.goodsOptList)
      item.goodsOptList.forEach((opt) => {
        opt.goodsOptMasterList.forEach((mst) => {
          amt += mst.goodsOptAmt * item.goodsCnt;
        });
      });
    return amt;
  }

  // 결제하기 버튼 클릭 시
  async onPayClick(): Promise<void> {
    const { dt, tm } = this.rsrvDttmFormGroup.value;
    const rsrvDttm = new Date(`${dayjs(dt).format('YYYY-MM-DD')} ${tm}`);

    if (dayjs(rsrvDttm).valueOf() <= dayjs().valueOf()) {
      await firstValueFrom(this.dialogService.alert('MSG.noPastRsrv'));
      return;
    }

    if (
      this.type === 'takeout' &&
      !isMrhstOpen(rsrvDttm, this.mrhst.mrhstOrdr)
    ) {
      await firstValueFrom(this.dialogService.alert('MSG.breaktimeTakeout'));

      if (environment.name !== 'prod') {
        await firstValueFrom(
          this.dialogService.alert('개발환경이므로 주문을 진행합니다.')
        );
      } else {
        return;
      }
    }

    if (
      this.type === 'delivery' &&
      this.getCartAmt() < this.mrhst.mrhstOrdr.delivStdAmt
    ) {
      this.dialogService
        .alert(
          this.translateService.instant('MSG.delivStdAmt', {
            amt: this.mrhst.mrhstOrdr.delivStdAmt,
          })
        )
        .subscribe();
      return;
    }

    const mobileNum = this.form.value.mobileNum || this.mobileNum;

    if (!mobileNum) {
      this.dialogService.alert('MSG.mobileNum').subscribe();
      return;
    }

    this.storageService.set('mobileNum', mobileNum);

    if (!(await firstValueFrom(this.checkStock()))) {
      return;
    }

    if (this.getPayAmt() <= 0) {
      // 결제할 금액 없으면 PG 결제 건너띔
      this.delngService
        .create(this.genDelng())
        .pipe(
          tap((delng) => {
            if (!delng?.id) {
              // TODO: delng 실패시 처리 검토
              return;
            }

            // 주문 성공 시 장바구니 초기화, 이용 내역 상세로 이동
            this.cartService.clear(this.type);
            this.matDialogRef.close();
            this.router.navigateByUrl(`/main/home?delngId=${delng.id}`);
          }),
          catchError((error) => {
            return this.checkStockAndThrowError(error);
          })
        )
        .subscribe();
      return;
    }

    const payMethod = this.form.value.payType;

    let pgParams;

    if (payMethod === 'PG_PAY') {
      pgParams = {
        itemCode: 'joyfood',
        itemName: this.getGoodsDesc(),
        itemPrice: this.getPayAmt(),
        orderNumber: this.epsilonPgService.makeOrderNumber(),
        userId: this.userInfo.id,
        userName: this.userInfo.userNm || this.userInfo.userId,
        userMailAdd: this.userInfo.email,
      };
    } else if (payMethod === 'LINE') {
      const delngGoodsList = this.genDelngGoodsList().map((v) => {
        return {
          goodsNm: v.goodsNm,
          goodsAmt: v.goodsAmt,
          goodsCnt: v.goodsCnt,
        };
      });

      pgParams = {
        pgType: 'LINE',
        goodsName: this.getGoodsDesc(),
        goodsAmt: this.getPayAmt(),
        tid: this.epsilonPgService.makeOrderNumber(),
        userId: this.userInfo.id,
        isWeb: true,
        // delngGoodsList: delngGoodsList.toString(),
        delngGoodsListJson: JSON.stringify(delngGoodsList),
      };
    } else if (payMethod === 'DFPAY') {
      this.dfPayAmt = this.getPayAmt();
      this.delngService
        .create(this.genDelng())
        .pipe(
          tap((delng) => {
            if (!delng?.id) {
              // TODO: delng 실패시 처리 검토
              return;
            }

            // 주문 성공 시 장바구니 초기화, 이용 내역 상세로 이동
            this.cartService.clear(this.type);
            this.matDialogRef.close();
            this.router.navigateByUrl(`/main/home?delngId=${delng.id}`);
          }),
          catchError((error) => {
            return this.checkStockAndThrowError(error);
          })
        )
        .subscribe();
      return;
    }

    this.dialogService.matDialog
      .open(PaymentComponent, {
        panelClass: 'fullscreen-modal',
        hasBackdrop: false,
        data: { pgParams, payMethod },
      })
      .beforeClosed()
      .pipe(
        mergeMap((pgPayResult: PgPayResult) => {
          return pgPayResult ? of(pgPayResult) : EMPTY;
        }),
        map((pgPayResult: PgPayResult) => {
          return this.genDelng(pgPayResult);
        }),
        mergeMap((delng: Delng) => {
          return this.delngService.create(delng);
        }),
        tap((delng) => {
          if (!delng?.id) {
            // TODO: delng 실패시 처리 검토
            return;
          }

          // 주문 성공 시 장바구니 초기화, 이용 내역 상세로 이동
          this.cartService.clear(this.type);
          this.matDialogRef.close();
          this.router.navigateByUrl(`/main/home?delngId=${delng.id}`);
        }),
        catchError((error) => {
          return this.checkStockAndThrowError(error);
        })
      )
      .subscribe();
  }

  trackBy(item: any): any {
    return item;
  }

  private checkStock(): Observable<boolean> {
    return forkJoin(
      this.cartData.map((data) => this.goodsService.findItem(data.goodsId))
    ).pipe(
      map((list) => {
        let msg = null;
        const noStockList = list.filter((goods) => goods.pendingFlg);
        if (noStockList.length) {
          msg = this.translateService.instant('MSG.goodsNoStock');
          msg += '<br/><br/>';
          noStockList.forEach((goods, i) => {
            msg += goods.goodsNm;
            if (i < noStockList.length - 1) {
              msg += '<br/>';
            }
          });
          this.dialogService.error(msg);
          return false;
        }
        return true;
      })
    );
  }

  private checkStockAndThrowError(error: Error): Observable<any> {
    return forkJoin(
      this.cartData.map((data) => this.goodsService.findItem(data.goodsId))
    ).pipe(
      mergeMap((list) => {
        let msg = null;
        const noStockList = list.filter((goods) => goods.pendingFlg);
        if (noStockList.length) {
          msg = this.translateService.instant('MSG.goodsNoStock');
          msg += '<br/><br/>';
          noStockList.forEach((goods, i) => {
            msg += goods.goodsNm;
            if (i < noStockList.length - 1) {
              msg += '<br/>';
            }
          });
          this.dialogService.error(msg);
        }
        return throwError(() => error);
      })
    );
  }

  /**
   * 주문 정보 생성
   *
   * @param pgResult PG 결과
   * @param this.username 회원 정보
   * @param this.mrhst 매장 정보
   * @returns 주문 정보
   */
  private genDelng(pgResult?: PgPayResult): Delng {
    const value = this.form.getRawValue();
    const paymentList: Payment[] = [];
    const payMethod = this.form.value.payType;

    if (pgResult?.amt > 0) {
      paymentList.push({
        pgPayId: pgResult.id,
        paymentType: payMethod,
        amt: pgResult.amt,
        userInfoId: this.userInfo.id,
      });
    }

    if (value.usePointAmt) {
      paymentList.push({
        paymentType: 'POINT',
        amt: value.usePointAmt,
        userInfoId: this.userInfo.id,
        userCardId: this.userCardId,
      });
    }

    if (this.dfPayAmt > 0) {
      paymentList.push({
        paymentType: 'DFPAY',
        amt: this.dfPayAmt,
        userInfoId: this.userInfo.id,
      });
    }

    let rsrvDttm = null;

    if (this.type === 'takeout') {
      const { dt, tm } = this.rsrvDttmFormGroup.value;
      rsrvDttm = `${dayjs(dt).format('YYYY-MM-DD')}T${tm}:00`;
    }

    const delng: Delng = {
      delngType: DelngType.APP,
      userInfo: { id: this.userInfo.id },
      mrhstId: this.mrhst.id,
      mrhstNm: this.mrhst.mrhstNm,
      totalAmt: this.getCartAmt() + this.getDelivAmt(),
      cpnId: this.cpn?.id || null,
      cpnAmt: this.getCpnAmt(this.cpn),
      // gcctId: this.gcct?.id || null,
      // gcctAmt: this.getGcctAmt(this.gcct),
      paymentList,
      delngOfferType:
        this.type === 'takeout' ? DelngOfferType.TKOUT : DelngOfferType.DELIV,
      delngOrdr: {
        mobileNum: this.form.value.mobileNum || this.mobileNum,
        reqCn: value.ordrReqCn,
        rsrvDttm,
      },
      delngGoodsList: this.genDelngGoodsList(),
    };

    if (this.type === 'delivery') {
      delng.delngDeliv = {
        rcvNm: this.userInfo.userNm || this.userInfo.userId,
        rcvAddr: this.addr,
        delivAmt: this.getDelivAmt(),
        reqCn: value.delivReqCnType || value.delivReqCn,
      };
    }

    return delng;
  }

  private genDelngGoodsList(): DelngGoods[] {
    return this.cartData.map<DelngGoods>((goods) => {
      return {
        goodsId: goods.goodsId,
        goodsNm: goods.goodsNm,
        goodsAmt: goods.goodsAmt,
        goodsCnt: goods.goodsCnt,
        goodsAttrJson: goods.goodsAttrJson,
        delngGoodsOptList: goods.goodsOptList.map<DelngGoodsOpt>((opt) => {
          return {
            id: opt.id,
            optTitle: opt.optTitle,
            multiChoiceFl: opt.multiChoiceFl,
            needFl: opt.requiredFl,
            goodsOptMasterList: opt.goodsOptMasterList.map<DelngGoodsOptMaster>(
              (mst) => {
                return {
                  id: mst.id,
                  goodsOptNm: mst.goodsOptNm,
                  goodsOptAmt: mst.goodsOptAmt,
                };
              }
            ),
          };
        }),
      };
    });
  }

  private setAddr(): void {
    const [addr] = this.histStorageService.getHist('address');
    this.addr = addr?.address;
  }

  private setUserInfo(): void {
    this.userInfoService
      .findItem(this.userInfo.id)
      .pipe(
        tap((userInfo) => {
          try {
            const { id, pointAmt } = userInfo.userCard;
            this.userCardId = id;
            this.pointAmt = pointAmt;
          } catch (error) {
            this.userCardId = null;
            this.pointAmt = 0;
          }
        })
      )
      .subscribe();
  }

  // private getMrhst(): void {
  //   this.mrhstDelivAmtService
  //     .findPage({ mrhstId: this.mrhst.id })
  //     .pipe(
  //       map((page) => page.content),
  //       tap((mrhstDelivAmtList: MrhstDelivAmt[]) => {
  //         mrhstDelivAmtList.forEach((mrhstDelivAmt) => {
  //           this.delivAmtMap.set(mrhstDelivAmt.addrCode.cd, mrhstDelivAmt);
  //         });
  //       }),
  //       shareReplay()
  //     )
  //     .subscribe();
  // }

  private getGoodsDesc(): string {
    const goodsList: DelngGoods[] = this.genDelngGoodsList();
    const firstGoodsNm = goodsList[0].goodsNm;
    let goodsCnt = 0;

    goodsList.forEach((goods) => {
      goodsCnt += goods.goodsCnt ?? 0;
    });

    return this.translateService.instant('delngDesc', {
      nm: firstGoodsNm,
      cnt: goodsCnt - 1,
    });
  }
}
