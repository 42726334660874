<!-- <div class="dialog">
  <mat-icon mat-dialog-close class="close-button">close</mat-icon>

  <p class="ask">{{ 'MSG.askLogin' | translate }}</p>

  <div fxLayoutGap="16px">
    <button
      fxFlex
      mat-flat-button
      round
      color="primary"
      (click)="onLoginClick()"
    >
      {{ 'login' | translate }}
    </button>

    <button
      fxFlex
      mat-flat-button
      round
      color="accent"
      (click)="onRegisterClick()"
    >
      {{ 'registerBtn' | translate }}
    </button>
  </div>
</div> -->
<main wrapper [formGroup]="loginForm">
  <button mat-icon-button class="close-button" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>

  <div class="logo-wrapper">
    <img src="/assets/img/joyfood-logo.png" />
  </div>

  <div class="login-container">
    <mat-form-field appearance="outline">
      <mat-label> {{ 'MSG.inputEmail' | translate }} </mat-label>
      <input
        matInput
        type="text"
        autocomplete="off"
        formControlName="id"
        (keyup)="onIdKeyup($event)"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label> {{ 'password' | translate }} </mat-label>
      <input
        matInput
        #pwForm
        type="password"
        [placeholder]="'password' | translate"
        formControlName="pw"
        (keyup)="onPwKeyup($event)"
      />
    </mat-form-field>

    <!-- <div>
      <mat-checkbox formControlName="shouldSaveId">
        <mat-label>{{ 'saveId' | translate }}</mat-label>
      </mat-checkbox>
    </div> -->

    <div class="button-wrapper">
      <button
        mat-flat-button
        round
        color="primary"
        class="login-button"
        (click)="onLoginClick()"
      >
        {{ 'login' | translate }}
      </button>
      <button
        *ngIf="showJoin"
        mat-flat-button
        round
        color="accent"
        class="login-button"
        (click)="onJoinClick()"
      >
        {{ 'registerBtn' | translate }}
      </button>
    </div>
    <div fxLayoutAlign="center center" class="sub-button-container">
      <a (click)="onFindClick()"> {{ 'resetPassword' | translate }}</a>
    </div>
  </div>
</main>
