import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { GoogleMapModule } from '../google-map/google-map.module';
import { SharedModule } from '../shared.module';
import { AddressFinderComponent } from './address-finder.component';

@NgModule({
  declarations: [AddressFinderComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    GoogleMapModule,
    SharedModule,
  ],
})
export class AddressFinderModule {}
