import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  Type,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'projects/pw-lib/src/public-api';
import { environment } from 'src/environments/environment';
import { CertService } from '../../../repository/cert/cert.service';
import { DialogService } from '../../dialog/dialog.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit, OnDestroy {
  BRAND_CD = `${environment.brandId}`;

  CORP_NO = `${environment.brandId}`;

  emailForm: FormGroup = this.formBuilder.group({
    email: [
      null,
      [
        Validators.required,
        Validators.pattern(
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ],
    ],
  });

  certForm: FormGroup = this.formBuilder.group({
    cert: [null, [Validators.required, Validators.pattern(/^[0-9]{1,6}$/)]],
  });

  isRequestPending = false;

  isSent = false;

  deepLinkCallback: (event: CustomEvent) => void;

  redirectTo: string[] | Type<any>;

  remainTimeTo = 0;

  remainTime = 0;

  interval: any;

  sendMailMsg: string;

  certId: number;

  constructor(
    private certService: CertService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private translateService: TranslateService,
    @Optional()
    private dialogRef: MatDialogRef<EmailComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    data: { redirectTo: Type<any>; sendMailMsg: string }
  ) {
    this.redirectTo = this.activatedRoute.snapshot.data.redirectTo;
    this.sendMailMsg = this.activatedRoute.snapshot.data.sendMailMsg;
    if (data?.redirectTo) {
      this.redirectTo = data.redirectTo;
    }
    if (data?.sendMailMsg) {
      this.sendMailMsg = data.sendMailMsg;
    }
  }

  ngOnInit(): void {
    // 이전 인증 정보 삭제
    this.storageService.delete('CERT_REMAIN_TO');
    this.onReset();
    const time = this.storageService.get('CERT_REMAIN_TO', 0);
    const email = this.storageService.get('CERT_EMAIL');
    this.certId = this.storageService.get('CERT_ID');
    if (time || email) {
      this.remainTimeTo = time;
      this.initInterval();
      this.emailForm.patchValue({ email });
    }
    if (this.certId) {
      this.certForm.enable();
    } else {
      this.certForm.disable();
    }
    this.deepLinkCallback = this.onDeepLinkReceived.bind(this);
    window.addEventListener('deepLink', this.deepLinkCallback);
  }

  ngOnDestroy(): void {
    window.removeEventListener('deepLink', this.deepLinkCallback);
  }

  onDeepLinkReceived(event: CustomEvent): void {
    // 응답 형식: <http|https>://<HOST>/<CERT_HASH>
    const certSplit = (<string>event.detail.link).split('/');
    const hash = certSplit[certSplit.length - 1];
    this.certService.checkCertEncrypted(hash).subscribe((result) => {
      if (result.result) {
        this.remainTimeTo = 0;
        this.storageService.delete('CERT_REMAIN_TO');
        this.storageService.delete('CERT_EMAIL');
        this.storageService.delete('CERT_ID');
        this.certForm.disable();
        if (Array.isArray(this.redirectTo)) {
          this.router.navigate(this.redirectTo, {
            queryParams: { receiver: result.receiver, certId: result.certId },
          });
        } else {
          this.dialogService.matDialog.open(this.redirectTo, {
            panelClass: 'fullscreen-modal',
            data: { receiver: result.receiver, certId: result.certId },
          });
          this.dialogRef.close();
        }
      }
    });
  }

  onSubmit(event: Event): void {
    event.preventDefault();
    this.emailForm.updateValueAndValidity();
    if (this.emailForm.valid && !this.isRequestPending) {
      this.isRequestPending = true;
      this.certService
        .requestCert('email', {
          brandCd: this.BRAND_CD,
          corpNo: this.CORP_NO,
          receiver: this.emailForm.value.email,
          reqType: 'CODE',
          // reqType: 'URL',
          // http(s) 주소 아무거나...
          // certUrlHost: 'http://joyfood.jp/',
        })
        .subscribe((res) => {
          const minute = Math.min(res.valid / 60);
          this.remainTimeTo = Date.now() + minute * 60 * 1000;
          this.storageService.set('CERT_REMAIN_TO', this.remainTimeTo);
          this.storageService.set('CERT_EMAIL', this.emailForm.value.email);
          this.certId = res.certId;
          this.storageService.set('CERT_ID', this.certId);
          this.certForm.reset();
          this.certForm.enable();
          this.initInterval();
          this.dialogService.alert(
            this.translateService.instant(this.sendMailMsg, {
              minute,
            })
          );
          this.isSent = true;
          this.isRequestPending = false;
        });
    }
  }

  onCertSubmit(event: Event): void {
    event.preventDefault();
    this.certForm.updateValueAndValidity();
    if (this.certForm.valid) {
      this.certService
        .checkCert('email', {
          brandCd: this.BRAND_CD,
          corpNo: this.CORP_NO,
          receiver: this.emailForm.value.email,
          certId: this.certId,
          certNum: this.certForm.value.cert,
        })
        .subscribe((result) => {
          if (result.result) {
            this.remainTimeTo = 0;
            this.storageService.delete('CERT_REMAIN_TO');
            this.storageService.delete('CERT_EMAIL');
            this.storageService.delete('CERT_ID');
            this.certForm.disable();
            if (Array.isArray(this.redirectTo)) {
              this.router.navigate(this.redirectTo, {
                queryParams: {
                  receiver: result.receiver,
                  certId: result.certId,
                },
              });
            } else {
              this.dialogService.matDialog.open(this.redirectTo, {
                panelClass: 'fullscreen-modal',
                data: { receiver: result.receiver, certId: result.certId },
              });
              this.dialogRef.close();
            }
          } else {
            this.dialogService.alert('MSG.certMismatch');
          }
        });
    }
  }

  onReset(): void {
    this.isSent = false;
    this.storageService.delete('CERT_EMAIL');
    this.storageService.delete('CERT_ID');
    this.certForm.reset();
    this.certForm.disable();
  }

  onBackClick(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
      return;
    }
    window.history.back();
  }

  private initInterval(): void {
    this.remainTime = this.remainTimeTo - Date.now();
    if (this.remainTime <= 0) {
      this.storageService.delete('CERT_REMAIN_TO');
      this.storageService.delete('CERT_EMAIL');
      this.storageService.delete('CERT_ID');
      this.certForm.disable();
      this.certId = null;
      return;
    }
    this.interval = setInterval(() => {
      this.remainTime = this.remainTimeTo - Date.now();
      if (this.remainTime <= 0) {
        this.storageService.delete('CERT_REMAIN_TO');
        this.storageService.delete('CERT_EMAIL');
        this.storageService.delete('CERT_ID');
        this.certForm.disable();
        this.certId = null;
        clearInterval(this.interval);
      }
    }, 1000);
  }
}
