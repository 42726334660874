<main wrapper>
  <app-joyfood-header>{{ 'changePassword' | translate }}</app-joyfood-header>
  <form class="reset-pw-form" [formGroup]="resetPwForm">
    <span class="title">{{ 'MSG.askNewPwInput' | translate }}</span>
    <mat-form-field appearance="outline">
      <mat-label> {{ 'password' | translate }} </mat-label>
      <input
        matInput
        type="password"
        autocomplete="new-password"
        formControlName="pw"
      />
      <mat-error *ngIf="resetPwForm?.get('pw')?.hasError('required')">
        {{ 'MSG.pwRequired' | translate }}
      </mat-error>
      <mat-error
        *ngIf="
          resetPwForm?.get('pw')?.hasError('minlength') ||
          resetPwForm?.get('pw')?.hasError('maxlength')
        "
      >
        {{ 'MSG.pwLength' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label> {{ 'checkPassword' | translate }} </mat-label>
      <input
        matInput
        type="password"
        autocomplete="new-password"
        formControlName="pwMatch"
      />
      <mat-error *ngIf="resetPwForm?.get('pwMatch')?.hasError('match')">
        {{ 'MSG.pwNotMatch' | translate }}
      </mat-error>
    </mat-form-field>

    <button
      mat-flat-button
      round
      class="register-button"
      color="primary"
      [disabled]="isLoading"
      (click)="onRegisterClick()"
    >
      {{ 'changePassword' | translate }}
    </button>
  </form>
</main>
